import cogoToast from "cogo-toast";
import { ReactElement, useContext, useEffect, useState } from "react";
import { Card, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import EvaluacionReparacionComponenteDetallesEtapa from "../components/evaluacionesReparaciones/EvaluacionReparacionComponenteDetallesEtapa";
import AuthContext from "../contexts/AuthContext";
import useBreadcrumb from "../hooks/useBreadcrumb";
import { getUserPermissionFunction } from "../utils/users";
import { EvaluacionOrReparacionComponenteEtapa } from "../models/EvaluacionesReparaciones";
import DetallesEvaluacionReparacionApi from "../api/EvaluacionesReparacionesApi";

const EvaluacionReparacionComponenteDetallesEtapaPage = (): ReactElement => {

    const { codigoOP, etapa } = useParams<{ codigoOP: string, etapa: string }>();
    const breadcrumb = useBreadcrumb();
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ evaluacionReparacionComponenteEtapa, setEvaluacionReparacionComponenteEtapa ] = useState<EvaluacionOrReparacionComponenteEtapa>();
    const { userRoles, userName, userEmail } = useContext(AuthContext);
    const isReparacion = window.location.pathname.includes('reparacion');
    const detallesApi = new DetallesEvaluacionReparacionApi(codigoOP, isReparacion);

    const changeEvaluacionReparacionComponenteEtapa = (newEvaluacionComponenteEtapa: EvaluacionOrReparacionComponenteEtapa) => {
        setEvaluacionReparacionComponenteEtapa({ ...newEvaluacionComponenteEtapa });
    }

    useEffect(() => {
        detallesApi.getEvaluacionOrReparacionComponenteEtapa(etapa)
            .then((response) => {
                if (response.data) {
                    setEvaluacionReparacionComponenteEtapa(response.data);
                } else {
                    cogoToast.error('No fue posible obtener el detalle de la evaluación');
                }
                setIsLoading(false);
            })
            .catch(() => {
                cogoToast.error('No fue posible obtener el detalle de la evaluación');
                setIsLoading(false);
            })
    }, [codigoOP, etapa])

    useEffect(() => {
        breadcrumb.setItems([
            {
                label: 'Estatus General',
                href: '/ordenesProceso'
            },
            {
                label: codigoOP,
                href: `/ordenesProceso/${codigoOP}`
            },
            {
                label: isReparacion ? 'Reparación' : 'Evaluación',
                href: `/ordenesProceso/${codigoOP}/${isReparacion ? 'reparacion' : 'evaluacion'}`
            },
            {
                label: etapa,
                inactive: true
            }
        ]);
    }, []);

    if (!evaluacionReparacionComponenteEtapa) {
        if (isLoading) {
            return (
                <Card>
                    <Card.Body className = "text-center">
                        <Spinner animation = "border" />
                    </Card.Body>
                </Card>
            );
        } else {
            return <p className = "text-center">{`No fue posible cargar el detalle de la ${isReparacion ? 'reparación' : 'evaluación'}`}</p>;
        }
    } else {
        return (
            <EvaluacionReparacionComponenteDetallesEtapa
                detallesApi = {detallesApi}
                codigoOP = {codigoOP}
                etapa = {etapa}
                userPermissionFunction = {getUserPermissionFunction(userRoles, userEmail)}
                userData = {{ userName: userName, userEmail: userEmail, userRoles: userRoles }}
                evaluacionReparacionComponenteEtapa = {evaluacionReparacionComponenteEtapa}
                changeEvaluacionReparacionComponenteEtapa = {changeEvaluacionReparacionComponenteEtapa}
            />
        );
    }
}

export default EvaluacionReparacionComponenteDetallesEtapaPage;