import { jsPDF } from "jspdf";
import { OrdenProceso } from "../models/estatusGeneral/OrdenProceso";
import logotipo from '../assets/images/logotipo.jpg';
import camion from '../assets/images/camion.jpg';
import { EvaluacionOrReparacionComponenteDetallesTable, EvaluacionOrReparacionComponenteEtapa, EvaluacionOrReparacionComponenteEtapaInforme, EvaluacionOrReparacionComponenteEtapaTarea, FinalCheck, InsumoElement, TareaObs } from "../models/EvaluacionesReparaciones";
import { ProcesoPhotoRequest, Archivo } from "../models/Archivos";
import { adjustDecimalOrFractionTextGreaterThan1, adjustDecimalTextLessThan1, formatArrayToString, formatGeneralText, checkNumber } from "./inputs";
import { combinePDFs } from "./archivos";

export class pdfElements {
    doc: jsPDF;
    x = 215.9;
    y = 279.4;
    margin = 14;
    cellsHeight = 5.5;
    headersRectanglesHeight = 17;
    spaceBetweenElements = 3;
    tablesFontSize = 10;
    rectangleTitleHeight = 6;
    generalFontSize = 12;
    totalPages = 1;
    totalItems = 0;
    subItemsCounter = 0;
    rectanglesFillColor: RGBColor = { ch1: 219, ch2: 229, ch3: 241 };
    titleCellsFillColor: RGBColor = { ch1: 146, ch2: 208, ch3: 80 };
    cellsFillColor: RGBColor = { ch1: 168, ch2: 208, ch3: 141 };
    greyColor: RGBColor = { ch1: 191, ch2: 191, ch3: 191 };
    redColor: RGBColor = { ch1: 255, ch2: 0, ch3: 0 };
    yellowColor: RGBColor = { ch1: 255, ch2: 255, ch3: 0 };
    green2Color: RGBColor = { ch1: 169, ch2: 208, ch3: 142 };
    totalUsableWidth: number;
    lastYCoordinate: number;
    lastXCoordinate: number;
    headersWidthUnit: number;
    firstYCoordinate: number;
    ordenProceso: OrdenProceso;
    finalEtapa: EvaluacionOrReparacionComponenteEtapa;
    etapas?: EvaluacionOrReparacionComponenteEtapaInforme[];
    fotos?: ProcesoPhotoRequest[];
    resumen?: Archivo;
    currentYCoordinate: number;

    constructor(ordenProceso: OrdenProceso, finalEtapa: EvaluacionOrReparacionComponenteEtapa, etapas?: EvaluacionOrReparacionComponenteEtapaInforme[], fotos?: ProcesoPhotoRequest[], resumen?: Archivo, isListadoRepuestos?: boolean) {
        const firstYCoordinate = this.margin + (!isListadoRepuestos ? ((this.headersRectanglesHeight * 2) + this.spaceBetweenElements) : 0);
        this.doc = new jsPDF('p', 'mm', [ this.x, this.y ]);
        this.headersWidthUnit = (this.x - (this.margin * 2)) * 0.1;
        this.firstYCoordinate = firstYCoordinate;
        this.totalUsableWidth = this.x - (this.margin * 2);
        this.lastYCoordinate = this.y - this.margin;
        this.lastXCoordinate = this.x - this.margin;
        this.ordenProceso = ordenProceso;
        this.finalEtapa = finalEtapa;
        this.etapas = etapas;
        this.fotos = fotos;
        this.resumen = resumen;
        this.currentYCoordinate = firstYCoordinate;
    }

    getRowFinalYCoordinate(yCoordinate: number, units: number): number {
        return (yCoordinate + (this.cellsHeight * units))
    }

    addPage() {
        this.doc.addPage();
        this.totalPages = this.totalPages + 1;
        this.currentYCoordinate = this.firstYCoordinate;
    }

    writeTextInCurrentDocumentPage(text: string, letterSize: number, yCoordinates: CoordinatesRange, underline?: boolean, bold?: boolean, italic?: boolean, xCoordinates?: CoordinatesRange, downToUp?: boolean) {
        let fixedText = text;
        getUnavailableSymbols().forEach((symbol) => fixedText = fixedText.replaceAll(symbol.symbol, symbol.replacement));
        const previousLineWidth = this.doc.getLineWidth();
        const previousDrawColor = this.doc.getDrawColor();
        this.doc.setFont('Times', italic ? 'italic' : 'normal', bold ? 'bold' : 'normal');
        this.doc.setFontSize(letterSize);
        this.doc.setLineWidth(0.1 * (Math.ceil(letterSize / 5)));
        this.doc.setDrawColor(0, 0, 0);
        const yCoordinateAdjustment = 0.05 * letterSize;
        const linesSpace = Math.floor(letterSize / 11);
        const xTextCoordinate1 = xCoordinates ? xCoordinates.start : 0;
        const xTextCoordinate2 = !xCoordinates || xCoordinates.end ? xCoordinates?.end || this.x : undefined;
        const lines = fixedText.split('\n');
        const lineHeight = this.doc.getTextDimensions(lines[0]).h;
        const yTextCoordinate = !yCoordinates.end ? yCoordinates.start + (!downToUp ? lineHeight : 0) : yCoordinates.start + ((yCoordinates.end - yCoordinates.start) / 2) - (((lines.length / 2) * lineHeight) + (Math.floor(lines.length / 2) * linesSpace)) + lineHeight;
        lines.forEach((line, index) => {
            const lineWidth = this.doc.getTextWidth(line);
            const lineYCoordinate = (yTextCoordinate - yCoordinateAdjustment) + (lineHeight * index) + (linesSpace * index);
            const lineXCoordinate = !xTextCoordinate2 ? xTextCoordinate1 : (((xTextCoordinate2 - xTextCoordinate1) / 2) + xTextCoordinate1) - (lineWidth / 2);
            this.doc.text(line, lineXCoordinate, lineYCoordinate);
            if (underline) {
                const underlineYCoordinate = lineYCoordinate + 1.25;
                this.doc.line(lineXCoordinate - 0.5, underlineYCoordinate, lineXCoordinate + lineWidth + 0.5, underlineYCoordinate);
            }
        });
        this.doc.setLineWidth(previousLineWidth);
        this.doc.setDrawColor(previousDrawColor);
    }

    addHeaders(title: string, code: string) {
        // const this = getPdfSettings();
        const paginationAndCodeFontSize = 11;
        const lowerRectangleStartingYCoordinate = this.margin + this.headersRectanglesHeight;
        const lowerRectangleEndingYCoordinate = this.margin + (this.headersRectanglesHeight * 2)
        const getRectangleWidthXCoordinate = (units: number): number => this.margin + (this.headersWidthUnit * units);
        for (let i = 1; i <= this.totalPages; i++) {
            this.doc.setPage(i);
            this.doc.setLineWidth(0);
            this.doc.setDrawColor(0,0,0);
            this.doc.rect(this.margin, this.margin, this.headersWidthUnit * 4, this.headersRectanglesHeight);
            this.doc.addImage(logotipo, 'JPEG', this.margin + 1, this.margin + 1, 50, 15);
            this.doc.addImage(camion, 'JPEG', this.margin + 1 + 50 + 6, this.margin + 1, 15, 15);
            this.doc.rect(getRectangleWidthXCoordinate(4), this.margin, this.headersWidthUnit * 6, this.headersRectanglesHeight);
            this.writeTextInCurrentDocumentPage(
                'CENTRO DE REPARACIÓN ANTOFAGASTA',
                13,
                { start: this.margin, end: lowerRectangleStartingYCoordinate },
                false,
                true,
                true,
                { start: getRectangleWidthXCoordinate(4), end: this.x - this.margin }
            );
            this.doc.rect(this.margin, lowerRectangleStartingYCoordinate, this.headersWidthUnit * 2, this.headersRectanglesHeight);
            this.writeTextInCurrentDocumentPage(
                `REGISTRO\nINFORME:\n${code.toUpperCase()}`,
                paginationAndCodeFontSize,
                { start: lowerRectangleStartingYCoordinate, end: lowerRectangleEndingYCoordinate },
                false,
                true,
                false,
                { start: this.margin, end: getRectangleWidthXCoordinate(2) }
            )
            this.doc.rect(getRectangleWidthXCoordinate(2), lowerRectangleStartingYCoordinate, this.headersWidthUnit * 6, this.headersRectanglesHeight);
            this.writeTextInCurrentDocumentPage(
                title,
                12,
                { start: lowerRectangleStartingYCoordinate, end: lowerRectangleEndingYCoordinate },
                false,
                true,
                false,
                { start: getRectangleWidthXCoordinate(2), end: getRectangleWidthXCoordinate(8) }
            )
            this.doc.rect(getRectangleWidthXCoordinate(8), lowerRectangleStartingYCoordinate, this.headersWidthUnit * 2, this.headersRectanglesHeight);
            this.writeTextInCurrentDocumentPage(
                `Página ${i} de ${this.totalPages}`,
                paginationAndCodeFontSize,
                { start: lowerRectangleStartingYCoordinate, end: lowerRectangleEndingYCoordinate },
                false,
                true,
                false,
                { start: getRectangleWidthXCoordinate(8), end: getRectangleWidthXCoordinate(10) }
            )
        }
    }

    addFrontPage(title: string, imagen: string) {
        // const this = getPdfSettings();
        const frontPageTitleClienteLettersSize = 18;
        const frontPageStartingYCoordinate = this.margin + (this.headersRectanglesHeight * 2);
        const frontPageOPNSLettersSize = 20;
        this.writeTextInCurrentDocumentPage(`${title.toUpperCase()} ${this.ordenProceso.componente?.modelo}${this.ordenProceso.recepcion?.subModeloComponente || ''}`, frontPageTitleClienteLettersSize, { start: frontPageStartingYCoordinate + 40 }, true, true, true);
        this.writeTextInCurrentDocumentPage(`CLIENTE: ${this.ordenProceso.cliente.toUpperCase()}`, frontPageTitleClienteLettersSize, { start: frontPageStartingYCoordinate + 62 }, true, true, true);
        this.writeTextInCurrentDocumentPage(`OP: ${this.ordenProceso.codigoOP.toUpperCase()}`, frontPageOPNSLettersSize, { start: frontPageStartingYCoordinate + 171 }, true, true, true);
        this.writeTextInCurrentDocumentPage(`N/S: ${this.ordenProceso.numeroSerieComponente.toUpperCase()}`, frontPageOPNSLettersSize, { start: frontPageStartingYCoordinate + 185 }, true, true, true);
        this.doc.setDrawColor(192,192,192);
        this.doc.setLineWidth(2.75);
        this.doc.line(51, 126.5, 157, 126.5);
        this.doc.line(155.5, 126.5, 155.5, 194);
        this.doc.setDrawColor(0,0,0);
        this.doc.setLineWidth(0);
        this.doc.rect(49, 128, 105, 68);
        this.doc.addImage(imagen, 'JPEG', 70.5, 133.5, 74, 51);
        this.doc.setLineWidth(0.5);
        this.doc.line(70, 185, 144, 185);
    }

    identificacionCliente(specialData?: { name: string, value: string }[], manufacter?: string) {
        // const this = getPdfSettings();
        const subtitle = 'IDENTIFICACIÓN DEL CLIENTE';
        const rectangleYCoordinate = this.firstYCoordinate + this.doc.getTextDimensions(subtitle, { fontSize: this.generalFontSize }).h + this.spaceBetweenElements;
        const rectangleWidth = this.x - (this.margin * 2);
        const rectangleRowsHeight = 10;
        const rectangleHegiht = rectangleRowsHeight * (4 + (!specialData ? 0 : Math.ceil(specialData.length / 2)));
        const rectangleColumnsWidth = rectangleWidth / 4;
        const margin = 1.5;
        const writeText = (text: string, yCoordinates: CoordinatesRange, underline?: boolean, bold?: boolean, italic?: boolean, xCoordinates?: CoordinatesRange, downToUp?: boolean) =>
            this.writeTextInCurrentDocumentPage(text, this.generalFontSize, yCoordinates, underline, bold, italic, xCoordinates, downToUp);
        this.doc.setPage(2);
        writeText(subtitle, { start: this.firstYCoordinate }, true, true, false, { start: this.margin + this.spaceBetweenElements });
        this.doc.setLineWidth(0);
        this.doc.setDrawColor(0, 0, 0);
        this.doc.rect(this.margin, rectangleYCoordinate, rectangleWidth, rectangleHegiht);
        const firstColumnTextXCoordinate = this.margin + margin;
        const firstColumnFinalXCoordinate = this.margin + rectangleColumnsWidth;
        const secondColumnTextXCoordinate = firstColumnFinalXCoordinate + margin;
        const secondColumnFinalXCoordinate = firstColumnFinalXCoordinate + rectangleColumnsWidth;
        const thirdColumnTextXCoordinate = secondColumnFinalXCoordinate + margin;
        const thirdColumnFinalXCoordinate = secondColumnFinalXCoordinate + rectangleColumnsWidth;
        const fourthColumnTextXCoordinate = thirdColumnFinalXCoordinate + margin;
        const firstRowBottomYCoordinate = rectangleYCoordinate + rectangleRowsHeight;
        const firstRowTextYCoordinate = firstRowBottomYCoordinate - margin;
        writeText('CLIENTE:', { start: firstRowTextYCoordinate }, false, true, true, { start: firstColumnTextXCoordinate }, true);
        writeText(`${this.ordenProceso.cliente.toUpperCase()}`, { start: firstRowTextYCoordinate }, false, false, true, { start: secondColumnTextXCoordinate }, true);
        writeText('FECHA:', { start: firstRowTextYCoordinate }, false, true, true, { start: thirdColumnTextXCoordinate }, true);
        writeText(`${this.ordenProceso.evaluacion?.fechaFinal ? this.ordenProceso.evaluacion?.fechaFinal : ''}`, { start: firstRowTextYCoordinate }, false, false, true, { start: fourthColumnTextXCoordinate }, true);
        const secondRowBottomYCoordinate = firstRowBottomYCoordinate + rectangleRowsHeight;
        const secondRowTextYCoordinate = secondRowBottomYCoordinate - margin;
        writeText('COMPONENTE:', { start: secondRowTextYCoordinate }, false, true, true, { start: firstColumnTextXCoordinate }, true);
        writeText(`${this.ordenProceso.componente?.descripcion.toUpperCase()}`, { start: secondRowTextYCoordinate }, false, false, true, { start: secondColumnTextXCoordinate }, true);
        writeText('OP:', { start: secondRowTextYCoordinate }, false, true, true, { start: thirdColumnTextXCoordinate }, true);
        writeText(`${this.ordenProceso.codigoOP.toUpperCase()}`, { start: secondRowTextYCoordinate }, false, false, true, { start: fourthColumnTextXCoordinate }, true);
        const thirdRowBottomYCoordinate = secondRowBottomYCoordinate + rectangleRowsHeight;
        const thirdRowTextYCoordinate = thirdRowBottomYCoordinate - margin;
        writeText('MODELO:', { start: thirdRowTextYCoordinate }, false, true, true, { start: firstColumnTextXCoordinate }, true);
        writeText(`${this.ordenProceso.componente?.modelo.toUpperCase()}${this.ordenProceso.recepcion?.subModeloComponente?.toUpperCase()}`, { start: thirdRowTextYCoordinate }, false, false, true, { start: secondColumnTextXCoordinate }, true);
        if (manufacter) {
            writeText('FABRICANTE:', { start: thirdRowTextYCoordinate }, false, true, true, { start: thirdColumnTextXCoordinate }, true);
            writeText(`${manufacter.toUpperCase()}`, { start: thirdRowTextYCoordinate }, false, false, true, { start: fourthColumnTextXCoordinate }, true);
        }
        // writeText(`${ordenProceso.componente?.fabricante.toUpperCase()}`, { start: thirdRowTextYCoordinate }, false, false, true, { start: fourthColumnTextXCoordinate }, true);
        let yCoordinate = thirdRowBottomYCoordinate + rectangleRowsHeight;
        specialData?.forEach((data, index) => {
            const specialDataCounter = index + 1;
            const newRowTextYCoordinate = yCoordinate - margin;
            const isOddElement = (specialDataCounter % 2 > 0) ? true : false;
            writeText(`${data.name.toUpperCase()}:`, { start: newRowTextYCoordinate }, false, true, true, { start: isOddElement ? firstColumnTextXCoordinate : thirdColumnTextXCoordinate }, true);
            writeText(`${data.value.toUpperCase()}`, { start: newRowTextYCoordinate }, false, false, true, { start: isOddElement ? secondColumnTextXCoordinate : fourthColumnTextXCoordinate }, true);
            if (!isOddElement || specialDataCounter === specialData.length) {
                yCoordinate = yCoordinate + rectangleRowsHeight;
            }
        });
        const lastRowTextYCoordinate = yCoordinate - margin;
        const servicioName = 'REQUERIMIENTO DE SERVICIO:';
        writeText(servicioName, { start: lastRowTextYCoordinate}, false, true, true, { start: firstColumnTextXCoordinate }, true);
        writeText(`EVALUACIÓN Y REPARACIÓN DE ${this.ordenProceso.componente?.tipoComponente.toUpperCase()}`, { start: lastRowTextYCoordinate }, false, false, true, { start: firstColumnTextXCoordinate + this.doc.getTextDimensions(servicioName, { fontSize: this.generalFontSize }).w + margin }, true);
        this.newCurrentYCoordinate(undefined, yCoordinate);
    }

    rectangleObs(title: string, obs?: TareaObs[], withRectangleSubtitle?: boolean, height?: number, horizontalSplit?: boolean) {
        // const this = getPdfSettings();
        const fontSize = 12;
        const totalWidth = this.x - (this.margin * 2);
        const obsMargins = 2;
        let newYCoordinate = this.currentYCoordinate;
        let newHeight = height;
        const obsWithValue = ((): number => {
            let response = 0;
            obs?.forEach((obsValue) => {
                if (obsValue.value) {
                    response = response + 1;
                }
            });
            return response;
        })();
        const widthPerObs = (!horizontalSplit || obsWithValue < 2) ? undefined : (this.x - (this.margin * 2)) / obsWithValue;
        const obsTexts = !obs ? undefined : ((): { title: string, text?: string }[] => {
            return obs.map((obsResponse) => {
                const title = `${obsResponse.name.replaceAll('Observaciones ', '')}:`;
                return {
                    title: obsResponse.value ? this.getStringWithLineBreaks(`${title.charAt(0).toUpperCase()}${title.slice(1)}`, fontSize, obsMargins, widthPerObs) : '',
                    text: obsResponse.value ? this.getStringWithLineBreaks(obsResponse.value, fontSize, obsMargins, widthPerObs) : undefined
                }
            });
        })();
        if (withRectangleSubtitle) {
            this.docSubtitleRectangle(title, newYCoordinate);
            newYCoordinate = newYCoordinate + this.rectangleTitleHeight + this.spaceBetweenElements;
        } else {
            const titleFinalYCoordinate = this.getRowFinalYCoordinate(newYCoordinate, 1);
            this.doc.setLineWidth(0);
            this.doc.rect(this.margin, newYCoordinate, totalWidth, this.cellsHeight);
            this.writeTextInCurrentDocumentPage(title, 12, { start: newYCoordinate, end: titleFinalYCoordinate }, false, true, false);
            newYCoordinate = titleFinalYCoordinate;
        }
        if (!newHeight || (newHeight + newYCoordinate > this.y - this.margin)) {
            newHeight = this.y - this.margin - newYCoordinate;
        }
        this.doc.rect(this.margin, newYCoordinate, totalWidth, newHeight);
        newYCoordinate = newYCoordinate + obsMargins;
        if (obsWithValue > 0) {
            let obsWithValueCounter = 0;
            obsTexts?.forEach((obsText) => {
                if (obsText.title) {
                    obsWithValueCounter = obsWithValueCounter + 1;
                    const lineHeight = this.doc.getTextDimensions(obsText.title, { fontSize: fontSize }).h;
                    // const contentYCoordinate = newYCoordinate + lineHeight;
                    let textStartYCoordinate = newYCoordinate + (obsText.title.split('\n').length * lineHeight) + 1.5;
                    let startXCoordinate = this.margin + obsMargins;
                    // let endXCoordinate;
                    if (widthPerObs) {
                        startXCoordinate = startXCoordinate + (widthPerObs * (obsWithValueCounter - 1));
                        // endXCoordinate = startXCoordinate + widthPerObs;
                    }
                    if (obsTexts.length > 1) {
                        this.writeTextInCurrentDocumentPage(
                            obsText.title,
                            fontSize,
                            { start: newYCoordinate },
                            true,
                            true,
                            false,
                            { start: startXCoordinate }
                        );
                    } else {
                        textStartYCoordinate = newYCoordinate;
                    }
                    this.writeTextInCurrentDocumentPage(
                        obsText.text || '',
                        fontSize,
                        { start: textStartYCoordinate },
                        false,
                        false,
                        false,
                        { start: startXCoordinate }
                    );
                    if (!horizontalSplit) {
                        newYCoordinate = textStartYCoordinate + (lineHeight * (obsText.text?.split('\n').length || 0)) + 3;
                    }
                }
            });
        }
        this.newCurrentYCoordinate(newHeight + (withRectangleSubtitle ? (this.rectangleTitleHeight + this.spaceBetweenElements) : this.cellsHeight));
    }
    
    standarTable = (columnsTitle: { text: string, boldColumn?: boolean }[], rowsTexts: string[][], title?: string) => {
        // const this = getPdfSettings();
        // let newYCoordinate = yCoordinate;
        const columnsDefaultWidth =  this.totalUsableWidth / columnsTitle.length;
        let finalColumnsTitle = { row: columnsTitle, highestHeight: 0 };
        // let finalRowsTexts = { rows: rowsTexts, highestHeight: 0 };
        let finalRowsTexts = rowsTexts.map((rowTexts) => ({ row: rowTexts, highestHeight: 0 }));
        let columnsNeededWidth: number[] = [];
        const columnsWidth: number[] = [];
        const checkSpace = (expectedYCoordinate: number) => {
            if (expectedYCoordinate > this.lastYCoordinate) {
                this.addPage();
            }
        };
        // const getColumnFirstXCoordinate = (columnIndex: number): number => this.margin + (columnIndex < 1 ? 0 : ((): number => {
        //     let columnsTotalWidthResponse = 0;
        //     for (let i = 0; i < columnIndex; i++) {
        //         columnsTotalWidthResponse = columnsTotalWidthResponse + columnsWidth[i];
        //     }
        //     return columnsTotalWidthResponse;
        // })());
        // const drawRect = (xCoordinate: number, yCoordinate: number, columnIndex: number) => this.doc.rect(xCoordinate, yCoordinate, columnsWidth[columnIndex], this.cellsHeight);
        // const divideRemainingSpaceInColumns = (columnsNeededWidthTotal: number) => {
        //     const remainingSpacePerColumn = (this.totalUsableWidth - columnsNeededWidthTotal) / columnsNeededWidth.length;
        //     columnsNeededWidth.forEach((columnNeededWidth) => columnsWidth.push(columnNeededWidth + remainingSpacePerColumn));
        // };
        const checkRowAndGetHigestHeight = (row: string[]): number => {
            let response = 0;
            row.forEach((cell, cellIndex) => {
                const lines = cell.split('\n');
                if (lines.length > response) {
                    response = lines.length;
                }
                lines.forEach((line) => {
                    const lineWidth = this.doc.getTextWidth(line);
                    if (columnsNeededWidth.length < finalColumnsTitle.row.length) {
                        columnsNeededWidth.push(lineWidth);
                    } else if (lineWidth > columnsNeededWidth[cellIndex]) {
                        columnsNeededWidth[cellIndex] = lineWidth;
                    }
                });
            });
            return response;
        };
        // const checkTextMaxWidth = (text: string, columnIndex: number) => text.split('\n').forEach((line) => {
        //     const lineWidth = this.doc.getTextWidth(line);
        //     if (columnsNeededWidth.length < finalColumnsTitle.row.length) {
        //         columnsNeededWidth.push(lineWidth);
        //     } else if (lineWidth > columnsNeededWidth[columnIndex]) {
        //         columnsNeededWidth[columnIndex] = lineWidth;
        //     }
        // });
        const adjustText = (text: string, maxWidth: number): string => {
            const response: string[] = [];
            text.split('\n').forEach((line) => {
                if (this.doc.getTextWidth(line) >= maxWidth) {
                    const stringMidIndex = Math.trunc(line.length / 2) - 1;
                    if (line.charAt(stringMidIndex) === ' ') {
                        response.push(line.substring(0, stringMidIndex));
                        response.push(line.substring(stringMidIndex + 1));
                    } else {
                        let leftPositionCounter = 0;
                        let rigthPositionCounter = 0;
                        for (let i = stringMidIndex - 1; i >= 0; i = i - 1) {
                            leftPositionCounter = leftPositionCounter + 1;
                            if (line.charAt(i) === ' ') {
                                break;
                            }
                        }
                        for (let i = stringMidIndex + 1; i < line.length; i++) {
                            rigthPositionCounter = rigthPositionCounter + 1;
                            if (line.charAt(i) === ' ') {
                                break;
                            }
                        }
                        if (leftPositionCounter <= rigthPositionCounter) {
                            const indexToCut = stringMidIndex - leftPositionCounter;
                            response.push(line.substring(0, indexToCut));
                            response.push(line.substring(indexToCut + 1));
                        } else {
                            const indexToCut = stringMidIndex + rigthPositionCounter;
                            response.push(line.substring(0, indexToCut));
                            response.push(line.substring(indexToCut + 1));
                        }
                    }
                } else {
                    response.push(line);
                }
            });
            return formatArrayToString(response, true);
        };
        // let counter = 0;
        while (true) {
            // finalColumnsTitle.row.forEach((columnTitleElements, columnTitleElementsIndex) => checkTextMaxWidth(columnTitleElements.text, columnTitleElementsIndex));
            // finalRowsTexts.rows.forEach((rowTexts) => rowTexts.forEach((cellText, cellTextIndex) => checkTextMaxWidth(cellText, cellTextIndex)));
            columnsNeededWidth = [];
            finalColumnsTitle.highestHeight = checkRowAndGetHigestHeight(finalColumnsTitle.row.map((rowTitle) => rowTitle.text));
            finalRowsTexts.forEach((rowTexts) => rowTexts.highestHeight = checkRowAndGetHigestHeight(rowTexts.row));
            const columnsNeededWidthTotal = ((): number => {
                let totalWidthResponse = 0;
                columnsNeededWidth.forEach((columnNeededWidth) => totalWidthResponse = totalWidthResponse + columnNeededWidth);
                return totalWidthResponse;
            })();
            // console.log(columnsNeededWidth)
            // console.log(columnsNeededWidthTotal)
            // if (counter === 1) {
            //     console.log(finalColumnsTitle)
            //     console.log(finalRowsTexts)
            // }
            if (this.totalUsableWidth >= columnsNeededWidthTotal) {
                if (columnsNeededWidth.some((columnNeededWidth) => columnNeededWidth > columnsDefaultWidth)) {
                    const remainingSpacePerColumn = (this.totalUsableWidth - columnsNeededWidthTotal) / finalColumnsTitle.row.length;
                    columnsNeededWidth.forEach((columnNeededWidth) => columnsWidth.push(columnNeededWidth + remainingSpacePerColumn));
                } else {
                    for (let i = 0; i < columnsNeededWidth.length; i++) {
                        columnsWidth.push(columnsDefaultWidth);
                    }
                }
                break;
            } else {
                const widerColumnsIndexes = ((): number[] => {
                    const response: number[] = [];
                    columnsNeededWidth.forEach((columnNeededWidth, columnNeededWidthIndex) => {
                        if (columnsNeededWidth.every((columnNeededWidthToCompare) => columnNeededWidth >= columnNeededWidthToCompare)) {
                            response.push(columnNeededWidthIndex);
                        }
                    })
                    return response;
                })();
                // if (counter === 0) {
                //     console.log(widerColumnsIndexes)
                // }
                widerColumnsIndexes.forEach((widerColumnIndex) => {
                    finalColumnsTitle.row[widerColumnIndex].text = adjustText(finalColumnsTitle.row[widerColumnIndex].text, columnsNeededWidth[widerColumnIndex]);
                    finalRowsTexts.forEach((rowTexts) => rowTexts.row[widerColumnIndex] = adjustText(rowTexts.row[widerColumnIndex], columnsNeededWidth[widerColumnIndex]));
                });
                // if (counter === 0) {
                //     console.log(finalColumnsTitle)
                //     console.log(finalRowsTexts)
                // }
                // console.log(counter)
            }
            // counter = counter + 1
            // if (counter > 2) {
            //     break
            // }
        }
        if (title) {
            // const titleFinalYCoordinate = newYCoordinate + this.spaceBetweenElements + this.doc.getTextDimensions(title).h;
            const titleHeight = this.doc.getTextDimensions(title).h;
            checkSpace(this.currentYCoordinate + titleHeight + this.spaceBetweenElements + (this.cellsHeight * ((): number => {
                let titlesHeight = 0;
                let firstRowHeight = 0;
                finalColumnsTitle.row.forEach((columnTitle) => {
                    const textLines = columnTitle.text.split('\n').length;
                    if (textLines > titlesHeight) {
                        titlesHeight = textLines;
                    }
                });
                finalRowsTexts.forEach((rowTexts) => rowTexts.row.forEach((cell) => {
                    const textLines = cell.split('\n').length;
                    if (textLines > firstRowHeight) {
                        firstRowHeight = textLines;
                    }
                }));
                return (titlesHeight + firstRowHeight);
            })()));
            this.writeTextInCurrentDocumentPage(title, this.tablesFontSize + 2, { start: this.currentYCoordinate }, true, true, false, { start: this.margin + 6 });
            // doc.setFont('Times', 'normal', 'normal');
            // const titleTextSize = pdfSettings.tablesTextSize + 2;
            // doc.setFontSize(titleTextSize);
            // doc.text(title, pdfSettings.margins + 6, newYCoordinate);
            // stringUnderline(doc, title, titleTextSize, pdfSettings.margins + 6, newYCoordinate);
            this.newCurrentYCoordinate(titleHeight);
        }
        // this.doc.setLineWidth(0);
        const printRow = (texts: string[], rowHeight: number, isTitleRow?: boolean, skipCheckSpace?: boolean) => {
            const rowHeightInCoordinates = rowHeight * this.cellsHeight;
            if (!skipCheckSpace) {
                checkSpace(this.currentYCoordinate + rowHeightInCoordinates);
            }
            texts.forEach((text, textIndex) => {
                const currentCellFirstXCoordinate = ((): number => {
                    let response = 0;
                    for (let i = 0; i < textIndex; i++) {
                        response = response + columnsWidth[i];
                    }
                    return (response + this.margin);
                })();
                this.doc.rect(currentCellFirstXCoordinate, this.currentYCoordinate, columnsWidth[textIndex], rowHeightInCoordinates)
                this.writeTextInCurrentDocumentPage(
                    text,
                    this.tablesFontSize,
                    { start: this.currentYCoordinate, end: this.currentYCoordinate + rowHeightInCoordinates },
                    false,
                    isTitleRow || finalColumnsTitle.row[textIndex].boldColumn,
                    false,
                    { start: currentCellFirstXCoordinate, end: currentCellFirstXCoordinate + columnsWidth[textIndex] }
                );
            });
            this.newCurrentYCoordinate(rowHeightInCoordinates, undefined, true);
        };
        printRow(finalColumnsTitle.row.map((columnTitle) => columnTitle.text), finalColumnsTitle.highestHeight, true, true);
        const firstRow = finalRowsTexts.shift();
        printRow(firstRow?.row || [], firstRow?.highestHeight || 0, false, true);
        finalRowsTexts.forEach((rowTexts) => printRow(rowTexts.row, rowTexts.highestHeight));
        // rowsTexts.forEach((row) => {
        //     checkSpace(newYCoordinate + this.cellsHeight);
        //     const currentRowFinalYCoordinate = newYCoordinate + this.cellsHeight;
        //     row.forEach((cell, cellIndex) => {
        //         const columnFirstXCoordinate = getColumnFirstXCoordinate(cellIndex);
        //         drawRect(columnFirstXCoordinate, newYCoordinate, cellIndex);
        //         this.writeTextInCurrentDocumentPage(
        //             cell,
        //             this.tablesFontSize,
        //             { start: newYCoordinate, end: currentRowFinalYCoordinate },
        //             false,
        //             columnsTitle[cellIndex].boldColumn,
        //             false,
        //             { start: columnFirstXCoordinate, end: columnFirstXCoordinate + columnsWidth[cellIndex] }
        //         );
        //     });
        //     newYCoordinate = currentRowFinalYCoordinate;
        // });
        this.newCurrentYCoordinate();
    }
    
    checkTablesPages(totImage?: { title: string, image: string, width?: number, height?: number }) {
        const trabajoPorRealizarList = this.finalEtapa.tareas.find((tarea) => tarea.nombre === 'Trabajo por realizar');
        const trabajoOtrosTalleresList = this.finalEtapa.tareas.find((tarea) => tarea.nombre === 'TOT por realizar');
        const checkTable = (title: string, task: EvaluacionOrReparacionComponenteEtapaTarea): number => {
            let response = 0;
            if (task.applyElements?.apply) {
                interface fixedFinalCheck { item: FinalCheck | InsumoElement, rowsUsed: number, fixedName: string };
                const items = task.finalCheckList;
                const insumos = ((): InsumoElement[] => {
                    const insumosResponse: InsumoElement[] = [];
                    task.insumosLists?.forEach((insumosList) => insumosList.insumos.forEach((insumo) => insumosResponse.push(insumo)));
                    return insumosResponse;
                })();
                // const this = getPdfSettings();
                const firstYCoordinate = this.firstYCoordinate;
                const tableMaxWidth = (this.x - (this.margin * 2) - this.spaceBetweenElements) / 2;
                const cellsLeftRightTotalMarings = 4;
                const secondColumnWidth = this.doc.getTextWidth('X') + cellsLeftRightTotalMarings;
                const elementNameColumnWidth = tableMaxWidth - secondColumnWidth;
                const firstYCoordinateAfterSubtitle = this.getRowFinalYCoordinate(firstYCoordinate, 1) + this.spaceBetweenElements;
                const page1MaxRowsInYAxis = Math.floor((this.y - (firstYCoordinateAfterSubtitle + this.margin)) / this.cellsHeight) - 1;
                const remaningPagesMaxRowsInYAxis = Math.floor((this.y - (firstYCoordinate + this.margin)) / this.cellsHeight) - 1;
                const writeTextInCurrentPage = (
                    text: string,
                    yCoordinates: CoordinatesRange,
                    underline?: boolean,
                    bold?: boolean,
                    italic?: boolean,
                    xCoordinates?: CoordinatesRange
                ) => this.writeTextInCurrentDocumentPage(text, this.tablesFontSize, yCoordinates, underline, bold, italic, xCoordinates);
                const getElementsRowsUsed = (elements: fixedFinalCheck[]): number => {
                    let rowsUsed = 0;
                    elements.forEach((element) => rowsUsed = rowsUsed + element.rowsUsed);
                    return rowsUsed;
                };
                const elementsInTables: fixedFinalCheck[] = (items ? items.checkList : insumos ? insumos : []).map((item) => {
                    const unitName = (item as any).unitName;
                    const fixedStringWihtLineBreaks = this.getStringWithLineBreaks(`${item.name.toUpperCase()}${unitName ? ` (${unitName})` : ''}`, this.tablesFontSize, 2, elementNameColumnWidth - cellsLeftRightTotalMarings);
                    return {
                        item: item,
                        rowsUsed: (fixedStringWihtLineBreaks.split('\n')).length,
                        fixedName: fixedStringWihtLineBreaks,
                    }
                });
                const itemsPerPageWithTables: { tables: fixedFinalCheck[][], isFull: boolean }[] = [];
                let pageCounter = 0;
                let tableCounter = 0;
                while (elementsInTables.length > 0) {
                    const element = elementsInTables.shift();
                    if (element) {
                        const addElementInNewPage = () => itemsPerPageWithTables.push({ tables: [[element]], isFull: false });
                        if (itemsPerPageWithTables.length === 0) {
                            addElementInNewPage();
                        } else {
                            if (getElementsRowsUsed([...itemsPerPageWithTables[pageCounter].tables[tableCounter], element]) > (pageCounter > 0 ? remaningPagesMaxRowsInYAxis : page1MaxRowsInYAxis)) {
                                if (tableCounter > 0) {
                                    itemsPerPageWithTables[pageCounter].isFull = true;
                                    addElementInNewPage();
                                    pageCounter = pageCounter + 1;
                                    tableCounter = 0;
                                } else {
                                    itemsPerPageWithTables[pageCounter].tables.push([element]);
                                    tableCounter = tableCounter + 1;
                                }
                            } else {
                                itemsPerPageWithTables[pageCounter].tables[tableCounter].push(element);
                            }
                        }
                    }
                };
                this.doc.setLineWidth(0);
                this.doc.setDrawColor(0, 0, 0);
                itemsPerPageWithTables.forEach((page, pageIndex) => {
                    this.addPage();
                    const tablesWithElements = page.tables;
                    let firstElementYCoordinate = firstYCoordinate;
                    if (pageIndex === 0) {
                        this.docSubtitleRectangle(title, firstElementYCoordinate);
                        firstElementYCoordinate = firstYCoordinateAfterSubtitle;
                    }
                    if (!page.isFull && tablesWithElements[0].length > 1) {
                        const addFirstTableLastElementToSecondTable = (createSecondTable?: boolean) => {
                            const newElementInTable = tablesWithElements[0].pop();
                            if (newElementInTable) {
                                if (createSecondTable) {
                                    tablesWithElements.push([newElementInTable]);
                                } else {
                                    tablesWithElements[1].unshift(newElementInTable);
                                }
                            }
                        }
                        if (tablesWithElements.length < 2) {
                            addFirstTableLastElementToSecondTable(true);
                        }
                        while (getElementsRowsUsed(tablesWithElements[0]) - tablesWithElements[0][tablesWithElements[0].length - 1].rowsUsed >= getElementsRowsUsed(tablesWithElements[1]) + tablesWithElements[0][tablesWithElements[0].length - 1].rowsUsed) {
                            addFirstTableLastElementToSecondTable();
                        }
                    }
                    tablesWithElements.forEach((table, tableIndex) => {
                        const tableXCoordinate = tableIndex > 0 ? this.margin + tableMaxWidth + this.spaceBetweenElements : this.margin;
                        const checkCellXCoordinate = tableXCoordinate + elementNameColumnWidth;
                        let currentYCoordinate = this.getRowFinalYCoordinate(firstElementYCoordinate, 1);
                        this.doc.setFillColor(this.rectanglesFillColor.ch1, this.rectanglesFillColor.ch2, this.rectanglesFillColor.ch3);
                        this.doc.rect(tableXCoordinate, firstElementYCoordinate, tableMaxWidth, this.cellsHeight, 'FD');
                        writeTextInCurrentPage(
                            items?.name || 'Repuestos e insumos',
                            { start: firstElementYCoordinate, end: this.getRowFinalYCoordinate(firstElementYCoordinate, 1) },
                            false,
                            true,
                            false,
                            { start: tableXCoordinate, end: tableXCoordinate + tableMaxWidth }
                        );
                        table.forEach((element, elementIndex) => {
                            const item = element.item as any;
                            const finalYCoordinate = this.getRowFinalYCoordinate(currentYCoordinate, element.rowsUsed);
                            const rowHeight = this.cellsHeight * element.rowsUsed;
                            this.doc.rect(tableXCoordinate, currentYCoordinate, elementNameColumnWidth, rowHeight);
                            writeTextInCurrentPage(
                                element.fixedName,
                                { start: currentYCoordinate, end: finalYCoordinate },
                                false,
                                false,
                                false,
                                { start: tableXCoordinate + (cellsLeftRightTotalMarings / 2) }
                            );
                            this.doc.rect(checkCellXCoordinate, currentYCoordinate, secondColumnWidth, rowHeight);
                            writeTextInCurrentPage(
                                items ? (item.check ? 'X' : '') : (item.amountRequested !== undefined ? item.amountRequested : ''),
                                // item.check ? 'X' : item.amountRequested !== undefined ? item.amountRequested : item.defaultAmount,
                                { start: currentYCoordinate, end: finalYCoordinate },
                                false,
                                false,
                                false,
                                { start: checkCellXCoordinate, end: checkCellXCoordinate + secondColumnWidth }
                            );
                            currentYCoordinate = finalYCoordinate;
                            if (pageIndex + 1 === itemsPerPageWithTables.length && tableIndex === 0 && elementIndex + 1 === table.length) {
                                response = currentYCoordinate;
                            }
                        });
                    });
                });
            }
            return response;
        };
        // let response = 0;
        if (trabajoPorRealizarList) {
            checkTable('TRABAJO POR REALIZAR', trabajoPorRealizarList);
        }
        if (trabajoOtrosTalleresList) {
            this.newCurrentYCoordinate(undefined, checkTable('TOT POR REALIZAR', trabajoOtrosTalleresList));
            if (totImage) {
                this.addParagraph(totImage.title, true, true, false);
                this.addImage(totImage.image, totImage.height, totImage.width, undefined, undefined, true);
            }
        }
        // return response;
    }
    
    approvingTable(isPreview?: boolean) {
        if (!isPreview) {
            const fechaFinal = this.ordenProceso.evaluacion?.fechaFinal;
            const participantesAdicionalesTable = this.finalEtapa.tareas.find((tarea) => tarea.nombre === 'Participantes adicionales')?.tables?.find((table) => table.name === '1');
            const revisadoPorRow = participantesAdicionalesTable?.rows.find((row) => row[0].content[0] === 'Revisado por');
            const controlCalidadRow = participantesAdicionalesTable?.rows.find((row) => row[0].content[0] === 'Control de calidad');
            const aprobadoPorRow = participantesAdicionalesTable?.rows.find((row) => row[0].content[0] === 'Aprobado por');
            const revisadoPor = revisadoPorRow ? revisadoPorRow[1].content : undefined;
            const aprobadoPor = aprobadoPorRow ? aprobadoPorRow[1].content : undefined;
            const controlCalidad = ((): string[] | undefined => {
                const response: string[] = [];
                this.etapas?.forEach((etapa) => etapa.aprobadoPor?.forEach((nombre) => {
                    if (!response.includes(nombre)) {
                        response.push(nombre);
                    }
                }));
                return (response.length > 0 ? response : controlCalidadRow ? controlCalidadRow[1].content : undefined);
            })();
            const preparadoPor = ((): string[] => {
                const response: string[] = [];
                this.etapas?.forEach((etapa) => etapa.realizadoPor?.forEach((nombre) => {
                    if (!response.includes(nombre)) {
                        response.push(nombre);
                    }
                }));
                return response;
            })();
            const cellsLeftRightMargins = 1;
            const cellsTopBottomMargins = 1;
            const fechaWidth = (cellsLeftRightMargins * 2) + this.doc.getTextDimensions(fechaFinal ? fechaFinal : 'DD-MM-AAAA', { fontSize: this.generalFontSize }).w;
            const cellsWidth = (this.x - (this.margin * 2) - fechaWidth) / 4;
            const cellsText: { title: string, text: string, titleHeight: number }[] = [
                { title: 'Preparado por:', text: preparadoPor },
                { title: 'Revisado por:', text: revisadoPor },
                { title: 'C. Calidad:', text: controlCalidad },
                { title: 'Aprobado por:', text: aprobadoPor },
                { title: 'Fecha:', text: fechaFinal ? [fechaFinal] : undefined }
            ].map((item) => ({
                title: item.title,
                titleHeight: this.doc.getTextDimensions(item.title, { fontSize: this.tablesFontSize }).h,
                text: !item.text ? '' : item.text.map((text) => this.getStringWithLineBreaks(`- ${text}`, this.tablesFontSize, cellsLeftRightMargins, cellsWidth)).toString().replaceAll(',', '\n'),
            }));
            const cellsHeight = ((): number => {
                let response = 0;
                cellsText.forEach((cellText) => {
                    const cellTextHeight = (cellsTopBottomMargins * 2) + ((): number => {
                        let response = cellText.titleHeight * (cellText.text ? 1 : 2);
                        cellText.text?.split('\n').forEach((line) => response = response + this.doc.getTextDimensions(line, { fontSize: this.tablesFontSize }).h);
                        return response;
                    })();
                    if (cellTextHeight > response) {
                        response = cellTextHeight;
                    }
                });
                return response;
            })();
            let yCoordinate = this.y - this.margin - cellsHeight;
            if (
                // newPage ||
                yCoordinate > this.lastYCoordinate
            ) {
                this.addPage();
                yCoordinate = this.firstYCoordinate;
            }
            let xCoordinate = this.margin;
            cellsText.forEach((cellText, index) => {
                const currentWidth = (index + 1) < cellsText.length ? cellsWidth : fechaWidth;
                this.doc.rect(xCoordinate, yCoordinate, currentWidth, cellsHeight);
                this.writeTextInCurrentDocumentPage(
                    cellText.title,
                    this.tablesFontSize,
                    { start: yCoordinate + cellsTopBottomMargins },
                    false,
                    true,
                    false,
                    { start: xCoordinate + cellsLeftRightMargins },
                );
                this.writeTextInCurrentDocumentPage(
                    cellText.text,
                    this.tablesFontSize,
                    { start: yCoordinate + cellsTopBottomMargins + cellText.titleHeight },
                    false,
                    false,
                    false,
                    { start: xCoordinate + cellsLeftRightMargins }
                );
                xCoordinate = xCoordinate + cellsWidth;
            });
        }
    }
    
    docSubtitleRectangle(text: string, yCoordinate?: number, isSubItem?: boolean) {
        const newYCoordinate = yCoordinate || this.currentYCoordinate;
        const previousLineWidth = this.doc.getLineWidth();
        const previousDrawColor = this.doc.getDrawColor();
        const previousFillColor = this.doc.getFillColor();
        if (!isSubItem) {
            this.totalItems = this.totalItems + 1;
            if (this.subItemsCounter > 1) {
                this.subItemsCounter = 0;
            }
        } else {
            this.subItemsCounter = this.subItemsCounter + 1;
        }
        this.doc.setFillColor(this.rectanglesFillColor.ch1, this.rectanglesFillColor.ch2, this.rectanglesFillColor.ch3);
        this.doc.rect(this.margin, newYCoordinate, this.x - (this.margin * 2), this.rectangleTitleHeight, 'FD');
        this.writeTextInCurrentDocumentPage(
            `${this.totalItems}.${isSubItem ? this.subItemsCounter : ''} ${text}`,
            // `${this.totalItems - (isSubItem ? ((this.totalItems > 1) ? 1 : 0) : 0)}.${isSubItem ? this.subItemsCounter : ''} ${text}`,
            this.tablesFontSize,
            { start: newYCoordinate, end: newYCoordinate + this.rectangleTitleHeight },
            false,
            true,
            false,
            { start: this.margin + 6 }
        );
        this.doc.setLineWidth(previousLineWidth);
        this.doc.setDrawColor(previousDrawColor);
        this.doc.setFillColor(previousFillColor);
        this.newCurrentYCoordinate(this.rectangleTitleHeight);
        // if (!isSubItem) {
        //     this.totalItems = this.totalItems + 1;
        //     if (this.subItemsCounter > 1) {
        //         this.subItemsCounter = 1;
        //     }
        // } else {
        //     this.subItemsCounter = this.subItemsCounter + 1;
        // }
    }
    
    componentesDañadosAndFaltantesList() {
        // const this = getPdfSettings();
        const initialCoordinate = this.currentYCoordinate;
        const textHeight = this.doc.getTextDimensions('Sin componentes', { fontSize: this.generalFontSize }).h;
        let componentesDañados = ['Sin componentes dañados'];
        let componentesFaltantes = ['Sin componentes faltantes'];
        const writeList = (title: string, list: string[]) => {
            const checkSpace = (futureYCoordinate: number) => {
                if (futureYCoordinate > this.lastYCoordinate) {
                    this.addPage();
                }
            }
            const firstElementYCoordinate = this.currentYCoordinate + this.rectangleTitleHeight + this.spaceBetweenElements;
            checkSpace(firstElementYCoordinate + textHeight);
            this.docSubtitleRectangle(title, undefined);
            this.currentYCoordinate = firstElementYCoordinate;
            list.forEach((item) => {
                checkSpace(this.currentYCoordinate + textHeight);
                this.writeTextInCurrentDocumentPage(
                    this.getStringWithLineBreaks(item, this.generalFontSize, this.spaceBetweenElements),
                    this.generalFontSize,
                    { start: this.currentYCoordinate },
                    false,
                    false,
                    true,
                    { start: this.margin + this.spaceBetweenElements }
                );
                this.currentYCoordinate = this.currentYCoordinate + textHeight;
            });
            this.currentYCoordinate = this.currentYCoordinate + this.spaceBetweenElements;
        };
        const tables = this.etapas?.find((etapa) => etapa.nombre === 'Recepción')?.tareas.find((tarea) => tarea.nombre === 'Revisar condición de recepción')?.tables;
        if (tables) {
            const pushLineToList = (list: string[], line: string) => list.push(this.getStringWithLineBreaks(line, this.generalFontSize, this.spaceBetweenElements));
            const deleteDefaultMessageInList = (list: string[]) => {
                if (list.length > 1) {
                    list.shift();
                }
            }
            tables[0].rows.forEach((row) => {
                const isMissing = row[1].content[0] === 'SÍ';
                if (isMissing || row[2].content[0] === 'SÍ') {
                    let line = `- ${row[0].content[0]}`;
                    if (isMissing) {
                        pushLineToList(componentesFaltantes, line);
                    } else {
                        line = `${line}${(row[3].content.length > 0) ? `: ${formatArrayToString(row[3].content)}` : ''}`;
                        pushLineToList(componentesDañados, line);
                    }
                }
            });
            deleteDefaultMessageInList(componentesDañados);
            deleteDefaultMessageInList(componentesFaltantes);
        }
        if (this.lastYCoordinate - initialCoordinate < ((this.rectangleTitleHeight + (this.spaceBetweenElements * 2)) * 2) + ((): number => {
            const getListHeight = (list: string[]): number => {
                let response = 0;
                list.forEach((item) => item.split('\n').forEach((line, lineIndex) => response = response + ((lineIndex + 1) * textHeight)));
                return response;
            }
            return (getListHeight(componentesDañados) + getListHeight(componentesFaltantes));
        })()) {
            this.addPage();
        }
        writeList('COMPONENTES DAÑADOS', componentesDañados);
        writeList('COMPONENTES FALTANTES', componentesFaltantes);
    }
    
    addPhotosToDoc(photos?: ProcesoPhotoRequest[]): number {
        // const this = getPdfSettings();
        const firstYCoordinate = this.firstYCoordinate;
        const tareaTitleHeight = this.doc.getTextDimensions('TAREA', { fontSize: this.generalFontSize }).h;
        const imageMarginValue = 1;
        const imageMargins = imageMarginValue * 2;
        const imageAndNameRectangleWidth = (this.x - (this.margin * 2)) / 2;
        const imageRectangleHeight = (this.y - this.margin - firstYCoordinate - this.rectangleTitleHeight - (tareaTitleHeight * 3) - (this.spaceBetweenElements * 8) - (this.cellsHeight * 6)) / 3;
        const totalRectangleHeight = imageRectangleHeight + this.cellsHeight;
        let yCoordinate = firstYCoordinate;
        const etapasTareasFotos: { etapa: string, tareas: { tarea: string, elementos: { elemento: string, foto: Archivo }[] }[] }[] = [];
        const writeText = (text: string, fontSize: number, yCoordinates: CoordinatesRange, underline: boolean, xCoordinates: CoordinatesRange) =>
            this.writeTextInCurrentDocumentPage(text, fontSize, yCoordinates, underline, true, false, xCoordinates);
        const usableSpace = (yCoordinate: number): number => this.y - this.margin - yCoordinate;
        (this.fotos || photos)?.forEach((photo) => {
            if (photo.imagen?.base64) {
                const photoElement = { elemento: photo.nombreVariable, foto: photo.imagen };
                const tareaPhotoElement = { tarea: photo.nombreTarea, elementos: [photoElement] };
                const etapaTareaPhotoElement = { etapa: photo.nombreEtapa, tareas: [tareaPhotoElement] };
                let doesEtapaExists = false;
                for (let etapa = 0; etapa < etapasTareasFotos.length; etapa++) {
                    if (photo.nombreEtapa === etapasTareasFotos[etapa].etapa) {
                        if (etapasTareasFotos[etapa].tareas && etapasTareasFotos[etapa].tareas.length > 0) {
                            let doesTareaExists = false;
                            for (let tarea = 0; tarea < etapasTareasFotos[etapa].tareas.length; tarea++) {
                                if (etapasTareasFotos[etapa].tareas[tarea].tarea === photo.nombreTarea) {
                                    etapasTareasFotos[etapa].tareas[tarea].elementos.push(photoElement);
                                    doesTareaExists = true;
                                    break;
                                }
                            }
                            if (!doesTareaExists) {
                                etapasTareasFotos[etapa].tareas.push(tareaPhotoElement);
                            }
                        } else {
                            etapasTareasFotos[etapa].tareas = [tareaPhotoElement];
                        }
                        doesEtapaExists = true;
                        break;
                    }
                }
                if (!doesEtapaExists) {
                    etapasTareasFotos.push(etapaTareaPhotoElement);
                }
            }
        });
        etapasTareasFotos.forEach((etapa, etapaIndex) => {
            if (etapaIndex === 0) {
                this.addPage();
                this.docSubtitleRectangle('REGISTRO FOTOGRÁFICO', yCoordinate);
                yCoordinate = yCoordinate + this.rectangleTitleHeight + this.spaceBetweenElements;
            } else if (usableSpace(yCoordinate) - this.cellsHeight - tareaTitleHeight - (this.spaceBetweenElements * 2) - totalRectangleHeight < -1) {
                this.addPage();
                yCoordinate = firstYCoordinate;
            }
            this.docSubtitleRectangle(`${etapa.etapa.toUpperCase()}`, yCoordinate, true);
            yCoordinate = yCoordinate + this.cellsHeight + this.spaceBetweenElements;
            etapa.tareas.forEach((tarea, tareaIndex) => {
                if (tareaIndex > 0 && usableSpace(yCoordinate) - tareaTitleHeight - this.spaceBetweenElements - totalRectangleHeight < -1) {
                    this.addPage();
                    yCoordinate = firstYCoordinate;
                }
                writeText(`${tarea.tarea}:`, this.generalFontSize, { start: yCoordinate }, true, { start: this.margin + this.spaceBetweenElements });
                yCoordinate = yCoordinate + tareaTitleHeight + this.spaceBetweenElements;
                tarea.elementos.forEach((elemento, elementoIndex) => {
                    if (elemento.foto.base64) {
                        const elementoName = elemento.elemento.toUpperCase();
                        const elementoCounter = elementoIndex + 1;
                        const isOddElement = (elementoCounter % 2) > 0 ? true : false;
                        if (isOddElement && usableSpace(yCoordinate) - totalRectangleHeight < -1) {
                            this.addPage();
                            yCoordinate = firstYCoordinate;
                        }
                        const imageXCoordinate = isOddElement ? this.margin : this.margin + imageAndNameRectangleWidth;
                        const nameYCoordinate = yCoordinate + imageRectangleHeight;
                        this.doc.rect(imageXCoordinate, yCoordinate, imageAndNameRectangleWidth, imageRectangleHeight);
                        this.doc.rect(imageXCoordinate, nameYCoordinate, imageAndNameRectangleWidth, this.cellsHeight);
                        this.doc.addImage(
                            elemento.foto.base64,
                            elemento.foto.nombre.substring(0, elemento.foto.nombre.indexOf(".")),
                            imageXCoordinate + imageMarginValue,
                            yCoordinate + imageMarginValue,
                            imageAndNameRectangleWidth - imageMargins,
                            imageRectangleHeight - imageMargins,
                            elemento.elemento
                        );                        
                        writeText(
                            elementoName,
                            ((): number => {
                                let newFontSize = this.tablesFontSize;
                                while (this.doc.getTextDimensions(elementoName, { fontSize: newFontSize }).w >= imageAndNameRectangleWidth) {
                                    newFontSize = newFontSize - (this.tablesFontSize * 0.05);
                                }
                                return newFontSize;
                            })(),
                            { start: nameYCoordinate, end: nameYCoordinate + this.cellsHeight },
                            false,
                            { start: imageXCoordinate, end: imageXCoordinate + imageAndNameRectangleWidth }
                        );
                        if (!isOddElement || elementoCounter === tarea.elementos.length) {
                            yCoordinate = yCoordinate + totalRectangleHeight + this.spaceBetweenElements;
                        }
                    }
                });
            });
        });
        return (yCoordinate > firstYCoordinate ? yCoordinate : 0);
    }
    
    recepcionItem() {
        // const this = getPdfSettings();
        // const itemText = 'Componente recepcionado en las instalaciones de Electrans para su evaluación y reparación';
        const imageRectangleHeight = 50;
        const imageRectangleWidth = 60;
        const imageMarginsUnit = 1;
        const imageMargins = imageMarginsUnit * 2;
        // const itemTextYCoordinate = this.currentYCoordinate + this.rectangleTitleHeight + this.spaceBetweenElements;
        // const imageYCoordinate = itemTextYCoordinate + this.doc.getTextDimensions(itemText, { fontSize: this.generalFontSize }).h + this.spaceBetweenElements;
        const imageXCoordinate = this.margin + ((this.x - (this.margin * 2)) / 2) - (imageRectangleWidth / 2);
        const image = this.fotos?.find((foto) => foto.nombreEtapa === 'Recepción' && foto.nombreTarea === 'Inspección visual' && foto.nombreVariable === 'Frontal 1');
        this.docSubtitleRectangle('RECEPCIÓN', this.currentYCoordinate);
        this.addParagraph('Componente recepcionado en las instalaciones de Electrans para su evaluación y reparación');
        // this.writeTextInCurrentDocumentPage(itemText, this.generalFontSize, { start: this.currentYCoordinate }, false, false, false);
        // this.newCurrentYCoordinate(imageRectangleHeight, imageYCoordinate);
        this.doc.rect(imageXCoordinate, this.currentYCoordinate, imageRectangleWidth, imageRectangleHeight);
        if (image?.imagen?.base64) {
            this.doc.addImage(
                image.imagen.base64,
                image.imagen.nombre.substring(0, image.imagen.nombre.indexOf(".")),
                imageXCoordinate + imageMarginsUnit,
                this.currentYCoordinate + imageMarginsUnit,
                imageRectangleWidth - imageMargins,
                imageRectangleHeight - imageMargins,
                image.nombreVariable
            );
        }
        this.newCurrentYCoordinate(imageRectangleHeight);
        // return (imageYCoordinate + imageRectangleHeight + this.spaceBetweenElements);
    }
    
    conclusionesGenerales() {
        const listadoTask = this.finalEtapa.tareas.find((tarea) => tarea.nombre === 'Listado de conclusiones generales');
        const textTask = this.finalEtapa.tareas.find((tarea) => tarea.nombre === 'Conclusiones generales adicionales');
        const margin = this.margin + this.spaceBetweenElements;
        let currentYCoordinate = this.firstYCoordinate;
        const firstYCoordinateAfterItemTitle = currentYCoordinate + this.rectangleTitleHeight + this.spaceBetweenElements;
        let isItemCreated = false;
        const createConclusionesItem = () => {
            if (!isItemCreated) {
                this.addPage();
                this.docSubtitleRectangle(`CONCLUSIONES GENERALES`, currentYCoordinate);
                currentYCoordinate = firstYCoordinateAfterItemTitle;
                isItemCreated = true;
            }
        };
        if (listadoTask?.applyElements?.apply && listadoTask?.finalCheckList) {
            const lines: string[] = [];
            listadoTask.finalCheckList.checkList.forEach((check) => {
                if (check.check) {
                    lines.push(this.getStringWithLineBreaks(`- ${check.name}`, this.generalFontSize, this.spaceBetweenElements));
                }
            });
            if (lines.length > 0) {
                lines.forEach((line, lineIndex) => {
                    if (lineIndex < 1) {
                        const firstLine = 'En la evaluación del Blower se solicita lo siguiente:';
                        createConclusionesItem();
                        this.writeTextInCurrentDocumentPage(firstLine, this.generalFontSize, { start: currentYCoordinate }, false, true, false, { start: margin });
                        currentYCoordinate = currentYCoordinate + this.doc.getTextDimensions(firstLine, { fontSize: this.generalFontSize }).h + this.spaceBetweenElements;
                    } else if (currentYCoordinate + this.doc.getTextDimensions(line, { fontSize: this.generalFontSize }).h >= this.y - this.margin) {
                        this.addPage();
                    }
                    this.writeTextInCurrentDocumentPage(line, this.generalFontSize, { start: currentYCoordinate }, false, false, false, { start: margin });
                    currentYCoordinate = currentYCoordinate + this.doc.getTextDimensions(line, { fontSize: this.generalFontSize }).h;
                });
            }
        }
        if (textTask?.applyElements?.apply && textTask.observations && textTask.observations.value) {
            const firstLine = 'Conclusiones adicionales:';
            const firstLineHeight = this.doc.getTextDimensions(firstLine, { fontSize: this.generalFontSize }).h;
            const obsText = this.getStringWithLineBreaks(textTask.observations.value, this.generalFontSize, margin);
            const obsTextHeight = ((): number => {
                let obsHeight = 0;
                obsText.split('\n').forEach((line) => obsHeight = obsHeight + this.doc.getTextDimensions(line, { fontSize: this.generalFontSize }).h);
                return obsHeight;
            })();
            createConclusionesItem();
            if (currentYCoordinate > firstYCoordinateAfterItemTitle) {
                currentYCoordinate = currentYCoordinate + this.spaceBetweenElements;
                if (this.y - this.margin <= currentYCoordinate + firstLineHeight + (this.spaceBetweenElements * 2) + obsTextHeight) {
                    this.addPage();
                }
                this.writeTextInCurrentDocumentPage(firstLine, this.generalFontSize, { start: currentYCoordinate }, false, true, false, { start: margin });
                currentYCoordinate = currentYCoordinate + firstLineHeight + this.spaceBetweenElements;
            }
            this.writeTextInCurrentDocumentPage(obsText, this.generalFontSize, { start: currentYCoordinate }, false, false, false, { start: margin });
            currentYCoordinate = currentYCoordinate + obsTextHeight + this.spaceBetweenElements;
        }
        this.newCurrentYCoordinate(undefined, currentYCoordinate);
    }
    
    saveDocument(isReparacion?: boolean, customTitle?: string) {
        const informeFileTitle = `${customTitle || `INFORME DE ${!isReparacion ? 'EVALUACION' : 'REPARACIÓN'}`} ${this.ordenProceso.componente?.tipoComponente} ${this.ordenProceso.codigoOP} DE ${this.ordenProceso.cliente}.pdf`;
        if (this.resumen?.base64) {
            combinePDFs(informeFileTitle, this.doc.output('datauristring'), [this.resumen]);
        } else {
            this.doc.save(informeFileTitle);
        }
    }

    getStringWithLineBreaks = (text: string, fontSize: number, margin?: number, totalWidth?: number): string => {
        // const this = getPdfSettings();
        const totalMargins = (margin || 0) * 2;
        const usableWidth = totalWidth ? totalWidth - totalMargins : this.x - (this.margin * 2) - totalMargins;
        const getTextWidth = (text: string): number => this.doc.getTextDimensions(text, { fontSize: fontSize }).w;
        let response = '';
        formatGeneralText(text).split('\n').map((line) => line.split(' ')).map((wordsInLine) => {
            const newLinesWithWords = [wordsInLine];
            let indentationSpace = '';
            if (newLinesWithWords[0].length > 1 && newLinesWithWords[0][0].charAt(0) === '-') {
                const spaceWidth = getTextWidth(' ');
                let indentation = '- ';
                if (newLinesWithWords[0][0].length > 1) {
                    indentation = '-';
                } else {
                    newLinesWithWords[0].splice(0, 2, `${newLinesWithWords[0][0]} ${newLinesWithWords[0][1]}`);
                }
                while (getTextWidth(indentationSpace) + (spaceWidth * 2) < getTextWidth(indentation)) {
                    indentationSpace = `${indentationSpace} `;
                }
            }
            for (let i = 0; i < newLinesWithWords.length; i++) {
                const newWordsInNewLine: string[] = [];
                while (newLinesWithWords[i].length > 1 && usableWidth < getTextWidth(((): string => {
                    let stringToCheckResponse = '';
                    newLinesWithWords[i].forEach((wordInLine, wordInLineIndex) => stringToCheckResponse = `${stringToCheckResponse}${wordInLineIndex > 0 ? ` ${wordInLine}` : wordInLine}`);
                    return stringToCheckResponse;
                })())) {
                    const lastWordInLine = newLinesWithWords[i].pop();
                    if (lastWordInLine) {
                        newWordsInNewLine.unshift(lastWordInLine);
                    }
                }
                if (newWordsInNewLine.length > 0) {
                    newLinesWithWords.push(newWordsInNewLine);
                }
            }
            if (indentationSpace) {
                for (let i = 1; i < newLinesWithWords.length; i++) {
                    newLinesWithWords[i].unshift(indentationSpace);
                }
            }
            return newLinesWithWords;
        }).forEach((originalLine, originalLineIndex) => originalLine.forEach((line, lineIndex) => {
            if (!(lineIndex === 0 && originalLineIndex === 0)) {
                response = `${response}\n`;
            }
            line.forEach((word, wordIndex) => response = `${response}${wordIndex > 0 ? ` ${word}`: word}`);
        }));
        return response;
    }

    actividadesEvaluacionOrReparacion = (actividades: string[], isReparacion?: boolean) => {
        this.docSubtitleRectangle(`ACTIVIDADES DE ${isReparacion ? 'REPARACIÓN' : 'EVALUACIÓN'}`, this.currentYCoordinate);
        this.addParagraph(`${!isReparacion ? 'Componente es recepcionado para revisión, evaluación, reparación e inspección general. ' : ''}Las actividades para ${isReparacion ? 'reparar' : 'evaluar'} su condición son:`);
        // this.currentYCoordinate = this.currentYCoordinate + this.spaceBetweenElements;
        actividades.forEach((actividad) => {
            this.writeTextInCurrentDocumentPage(`- ${actividad}`, this.generalFontSize, { start: this.currentYCoordinate }, false, true, true, { start: this.margin + 14 });
            this.currentYCoordinate = this.currentYCoordinate + this.doc.getTextDimensions(actividad).h;
        });
        this.newCurrentYCoordinate();
    }

    addParagraph = (text: string, underline?: boolean, bold?: boolean, italic?: boolean) => {
        const textWithLinesBreaks = this.getStringWithLineBreaks(text, this.generalFontSize);
        this.writeTextInCurrentDocumentPage(textWithLinesBreaks, this.generalFontSize, { start: this.currentYCoordinate }, underline, bold, italic);
        this.newCurrentYCoordinate(this.getLinesTotalHeight(textWithLinesBreaks));
        // return (newYCoordinate + this.getLinesTotalHeight(textWithLinesBreaks) + this.spaceBetweenElements);
    }

    getLinesTotalHeight = (text: string): number => {
        let response = 0;
        text.split('\n').forEach((line) => response = response + this.doc.getTextDimensions(line).h);
        return response;
    }

    addTable = (
        rowsSets: {
            rows: {
                text: string,
                bold?: boolean,
                color?: RGBColor,
                cellHeightInUnits?: number,
                cellsWidthInUnits?: number
            }[][],
            rowCellsHeightInUnits?: number,
            rowsColor?: RGBColor,
            totalCellsWidthInUnits?: number,
            allCellsTogether?: boolean
        }[],
        totalWidth?: number,
        skipNewCurrentYCoordinate?: boolean,
        xCoordinate?: number,
    ): number => {
        // el conjunto de filas tienen más de una fila cuando todas las filas son iguales en altura
        // la propiedad de cada celda se ignora si el conjunto de las filas tiene esa propiedad, excepto el ancho
        // el conjunto de varias filas o una fila con la propiedad allCellsTogether va en un solo espacio
        const firstXCoordinate = xCoordinate || this.margin;
        const width = totalWidth && firstXCoordinate + totalWidth <= this.x - this.margin ? totalWidth : this.x - this.margin - firstXCoordinate;
        let currentYCoordinate = this.currentYCoordinate;
        if (this.totalUsableWidth + this.margin >= firstXCoordinate + width) {
            interface Coordinate { x: number, y: number };
            const checkCoordinateInArray = (coordinate: Coordinate, array: Coordinate[]): boolean => {
                let response = false;
                for (let i = 0; i < array.length; i++) {
                    if (Math.round(array[i].x) === Math.round(coordinate.x) && Math.round(array[i].y) === Math.round(coordinate.y)) {
                        response = true;
                        break;
                    }
                }
                return response;
            };
            const addCell = (x: number, y: number, width: number, height: number, text: string, finalYCoordinate: number, finalXCoordinate: number, bold?: boolean, color?: RGBColor) => {
                const previousFillColor = this.doc.getFillColor();
                if (color) {
                    this.doc.setFillColor(color.ch1, color.ch2, color.ch3);
                }
                this.doc.rect(x, y, width, height, color ? 'FD' : undefined);
                this.doc.setFillColor(previousFillColor);
                this.writeTextInCurrentDocumentPage(text, this.tablesFontSize, { start: y, end: finalYCoordinate }, false, bold, false, { start: x, end: finalXCoordinate });
            }
            if (this.lastYCoordinate < currentYCoordinate + ((): number => {
                let tableHeight = 0;
                let ignoreCellsHeightCounter = 0;
                const checkNewTableHeight = (newRowsHeight: number) => {
                    if (ignoreCellsHeightCounter > 0) {
                        const totalCellsHeightCounter = ignoreCellsHeightCounter - newRowsHeight;
                        if (totalCellsHeightCounter < 0) {
                            tableHeight = tableHeight - (totalCellsHeightCounter);
                            ignoreCellsHeightCounter = 0;
                        } else {
                            ignoreCellsHeightCounter = totalCellsHeightCounter;
                        }
                    } else {
                        tableHeight = tableHeight + newRowsHeight;
                    }
                }
                rowsSets.forEach((rowsSet) => {
                    if (rowsSet.rowCellsHeightInUnits || rowsSet.rows.length > 1) {
                        checkNewTableHeight((rowsSet.rowCellsHeightInUnits || 1) * rowsSet.rows.length);
                    } else {
                        let rowHighestCellsHeight = 1;
                        rowsSet.rows[0].forEach((cell) => {
                            if (cell.cellHeightInUnits && cell.cellHeightInUnits > rowHighestCellsHeight) {
                                rowHighestCellsHeight = cell.cellHeightInUnits;
                            }
                        });
                        checkNewTableHeight(rowHighestCellsHeight);
                    }
                });
                return tableHeight;
            })()) {
                this.addPage();
                currentYCoordinate = this.firstYCoordinate;
            }
            let existingCellsBorders: Coordinate[] = [{ x: firstXCoordinate, y: this.currentYCoordinate }, { x: firstXCoordinate + width, y: this.currentYCoordinate }];
            rowsSets.forEach((rowsSet) => {
                const coordinatesWithLowestYCoordinate = ((): Coordinate[][] => {
                    let coordinates: Coordinate[] = [];
                    let response: Coordinate[][] = [];
                    existingCellsBorders.forEach((coordinate) => {
                        if (coordinates.length < 1 || coordinates[coordinates.length - 1].y === coordinate.y) {
                            coordinates.push(coordinate);
                        } else if (coordinates[coordinates.length - 1].y > coordinate.y) {
                            coordinates = [coordinate];
                        }
                    });
                    for (let i = 0; i < coordinates.length; i = i + 2) {
                        response.push([coordinates[i], coordinates[i + 1]]);
                    }
                    return response;
                })();
                let rowsSetCurrentXCoordinate = coordinatesWithLowestYCoordinate[0][0].x;
                let rowsSetCurrentYCoordinate = coordinatesWithLowestYCoordinate[0][0].y;
                let coordinatesUpdate: Coordinate[][] = [];
                if (rowsSet.allCellsTogether || rowsSet.rows.length > 1) {
                    const cellsHeight = this.cellsHeight * (rowsSet.rowCellsHeightInUnits || 1);
                    const cellsUnitWidth = (coordinatesWithLowestYCoordinate[0][1].x - coordinatesWithLowestYCoordinate[0][0].x) / (rowsSet.totalCellsWidthInUnits || rowsSet.rows[0].length);
                    const totalCellsHeight = rowsSet.rows.length * cellsHeight;
                    rowsSet.rows.forEach((row) => {
                        const finalYCoordinate = rowsSetCurrentYCoordinate + cellsHeight;
                        // let doesItBreak = false;
                        for (let i = 0; i < row.length; i++) {
                            const currentCellWidth = cellsUnitWidth * (row[i].cellsWidthInUnits || 1);
                            const finalXCoordinate = rowsSetCurrentXCoordinate + currentCellWidth;
                            if (Math.round(coordinatesWithLowestYCoordinate[0][1].x) >= Math.round(finalXCoordinate)) {
                                addCell(
                                    rowsSetCurrentXCoordinate,
                                    rowsSetCurrentYCoordinate,
                                    currentCellWidth,
                                    cellsHeight,
                                    row[i].text,
                                    finalYCoordinate,
                                    finalXCoordinate,
                                    row[i].bold,
                                    rowsSet.rowsColor || row[i].color
                                );
                                // this.doc.rect(rowsSetCurrentXCoordinate, rowsSetCurrentYCoordinate, currentCellWidthInUnits, cellsHeight);
                                // this.writeTextInCurrentDocumentPage(row[i].text, this.tablesFontSize, { start: rowsSetCurrentYCoordinate, end: finalYCoordinate }, false, false, false, { start: rowsSetCurrentXCoordinate, end: finalXCoordinate });
                                rowsSetCurrentXCoordinate = finalXCoordinate;
                            } else {
                                // doesItBreak = true
                                break;
                            }
                        }
                        rowsSetCurrentXCoordinate = coordinatesWithLowestYCoordinate[0][0].x
                        rowsSetCurrentYCoordinate = finalYCoordinate;
                    });
                    coordinatesUpdate = [[
                        { ...coordinatesWithLowestYCoordinate[0][0], y: coordinatesWithLowestYCoordinate[0][0].y + totalCellsHeight },
                        { ...coordinatesWithLowestYCoordinate[0][1], y: coordinatesWithLowestYCoordinate[0][1].y + totalCellsHeight }
                    ]];
                } else {
                    const cellsUnitWidth = ((): number => {
                        const numberOfSpaces = (coordinatesWithLowestYCoordinate.length / 2);
                        let cellsUnitsNumber = rowsSet.totalCellsWidthInUnits || rowsSet.rows[0].length;
                        if (numberOfSpaces > cellsUnitsNumber) {
                            cellsUnitsNumber = numberOfSpaces;
                        }
                        let totalWidth = 0;
                        for (let i = 0; i < coordinatesWithLowestYCoordinate.length; i++) {
                            totalWidth = totalWidth + (coordinatesWithLowestYCoordinate[i][1].x - coordinatesWithLowestYCoordinate[i][0].x);
                        }
                        if (cellsUnitsNumber > 1 && numberOfSpaces > 1 && cellsUnitsNumber % 2 !== 0) {
                            cellsUnitsNumber = cellsUnitsNumber + 1;
                        }
                        return (totalWidth / cellsUnitsNumber);
                    })();
                    let spaceCounter = 0;
                    rowsSet.rows[0].forEach((cell) => {
                        const currentCellWidth = cellsUnitWidth * (cell.cellsWidthInUnits || 1);
                        let finalXCoordinate = rowsSetCurrentXCoordinate + currentCellWidth;
                        let doesCellFits = Math.round(coordinatesWithLowestYCoordinate[spaceCounter][1].x) >= Math.round(finalXCoordinate);
                        while (!doesCellFits && spaceCounter < coordinatesWithLowestYCoordinate.length - 1) {
                            spaceCounter = spaceCounter + 1;
                            rowsSetCurrentXCoordinate = coordinatesWithLowestYCoordinate[spaceCounter][0].x;
                            finalXCoordinate = rowsSetCurrentXCoordinate + currentCellWidth;
                            doesCellFits = Math.round(coordinatesWithLowestYCoordinate[spaceCounter][1].x) >= Math.round(finalXCoordinate);
                        }
                        if (doesCellFits) {
                            const currentCellHeight = this.cellsHeight * (rowsSet.rowCellsHeightInUnits || cell.cellHeightInUnits || 1);
                            const finalYCoordinate = coordinatesWithLowestYCoordinate[spaceCounter][0].y + currentCellHeight;
                            addCell(
                                rowsSetCurrentXCoordinate,
                                rowsSetCurrentYCoordinate,
                                currentCellWidth,
                                currentCellHeight,
                                cell.text,
                                finalYCoordinate,
                                finalXCoordinate,
                                cell.bold,
                                cell.color || rowsSet.rowsColor
                            );
                            coordinatesUpdate.push([{ x: rowsSetCurrentXCoordinate, y: finalYCoordinate }, { x: finalXCoordinate, y: finalYCoordinate }])
                            rowsSetCurrentXCoordinate = finalXCoordinate;
                        }
                    });
                }
                const flatedCoordinatesWithLowestYCoordinate = coordinatesWithLowestYCoordinate.flat();
                existingCellsBorders = ((): Coordinate[] => {
                    const response: Coordinate[] = [];
                    existingCellsBorders.forEach((coordinate) => {
                        if (!checkCoordinateInArray(coordinate, flatedCoordinatesWithLowestYCoordinate)) {
                            response.push(coordinate);
                        }
                    });
                    return response;
                })();
                existingCellsBorders = ((): Coordinate[] => {
                    let response: Coordinate[] = [];
                    if (existingCellsBorders.length < 1) {
                        response = coordinatesUpdate.flat(); 
                    } else {
                        coordinatesWithLowestYCoordinate.forEach((space, spaceIndex) => {
                            const coordinatesForCurrentSpace: Coordinate[] = [];
                            const addCoordinatesToSpace = (initialCoordinates: Coordinate[]): boolean => {
                                (coordinatesForCurrentSpace.length > 1 ? coordinatesForCurrentSpace : initialCoordinates).forEach((coordinateCurrentSpace) => response.push(coordinateCurrentSpace));
                                return true;
                            };
                            coordinatesUpdate.forEach((coordinates) => {
                                if (Math.round(space[0].x) <= Math.round(coordinates[0].x) && Math.round(coordinates[1].x) >= Math.round(space[1].x)) {
                                    coordinatesForCurrentSpace.push(coordinates[0]);
                                    coordinatesForCurrentSpace.push(coordinates[1]);
                                }
                            });
                            for (let i = 0; i < existingCellsBorders.length; i++) {
                                let wereCoordinatesAdded = false;
                                if (Math.round(existingCellsBorders[i].x) === Math.round(space[0].x)) {
                                    for (let e = 0; e <= i; e++) {
                                        if (!checkCoordinateInArray(existingCellsBorders[e], response)) {
                                            response.push(existingCellsBorders[e]);
                                        }
                                    }
                                    wereCoordinatesAdded = addCoordinatesToSpace(space);
                                } else if (Math.round(existingCellsBorders[i].x) === Math.round(space[1].x)) {
                                    wereCoordinatesAdded = addCoordinatesToSpace(space);
                                }
                                if (wereCoordinatesAdded) {
                                    if (spaceIndex === coordinatesWithLowestYCoordinate.length - 1) {
                                        for (let e = i + 1; e < existingCellsBorders.length; e++) {
                                            response.push(existingCellsBorders[e]);
                                        }
                                    } else {
                                        break;
                                    }
                                }
                            }
                        });
                    }
                    return response;
                })();
                existingCellsBorders = ((): Coordinate[] => {
                    const response: Coordinate[] = [];
                    existingCellsBorders.forEach((coordinate, coordinateIndex) => {
                        const nextCoordinateIndex = coordinateIndex + 1;
                        const lastCoordinatePushedIndex = response.length - 1;
                        if (response.length < 1) {
                            response.push(coordinate);
                        } else {
                            if (Math.round(coordinate.x) !== Math.round(response[lastCoordinatePushedIndex].x)) {
                                if (nextCoordinateIndex === existingCellsBorders.length || Math.round(coordinate.y) !== Math.round(existingCellsBorders[nextCoordinateIndex].y)) {
                                    response.push(coordinate);
                                }
                            } else if (Math.round(coordinate.y) !== Math.round(response[lastCoordinatePushedIndex].y) && nextCoordinateIndex < existingCellsBorders.length) {
                                response.push(coordinate);
                            }
                        }
                    });
                    return response;
                })();
            });
            currentYCoordinate = ((): number => {
                let biggestYCoordinate = 0;
                for (let i = 0; i < existingCellsBorders.length; i++) {
                    if (existingCellsBorders[i].y > biggestYCoordinate) {
                        biggestYCoordinate = existingCellsBorders[i].y;
                    }
                }
                return biggestYCoordinate;
            })();
        }
        if (!skipNewCurrentYCoordinate) {
            this.newCurrentYCoordinate(undefined, currentYCoordinate);
        }
        return (width + (xCoordinate || this.margin) + (totalWidth ? this.spaceBetweenElements : 0));
    }

    newCurrentYCoordinate = (height?: number, newYCoordinate?: number, skipSpaceBetweenElements?: boolean) => this.currentYCoordinate = (newYCoordinate || this.currentYCoordinate) + (height || 0) + (!skipSpaceBetweenElements ? this.spaceBetweenElements : 0);

    addImage = (image: string, height?: number, width?: number, xCoordinate?: number, yCoordinate?: number, isCenteredInXMaxSpace?: boolean, isCenteredInYMaxSpace?: boolean, squareImage?: boolean, skipNewCurrentYCoordinate?: boolean): number => {
        const newXCoordinate = xCoordinate || this.margin;
        const newYCoordinate = yCoordinate || this.currentYCoordinate;
        const getNewWidthOrHeight = (isHeight?: boolean): number => {
            let newCoordinateToEvaluate = newXCoordinate;
            let totalToEvaluate = width;
            let lastCoordinateToEvaluate = this.lastXCoordinate;
            if (isHeight) {
                newCoordinateToEvaluate = newYCoordinate;
                totalToEvaluate = height;
                lastCoordinateToEvaluate = this.lastYCoordinate;
            }
            const maxPossible = lastCoordinateToEvaluate - newCoordinateToEvaluate;
            if (!totalToEvaluate) {
                return maxPossible;
            } else {
                if (newCoordinateToEvaluate + totalToEvaluate <= lastCoordinateToEvaluate) {
                    return totalToEvaluate;
                } else {
                    return maxPossible 
                }
            }
        }
        const newWidth = getNewWidthOrHeight();
        const newHeight = getNewWidthOrHeight(true);
        let imageWidth = newWidth;
        let imageHeight = newHeight;
        let imageXCoordinate = newXCoordinate;
        let imageYCoordinate = newYCoordinate;
        if (squareImage && imageWidth !== imageHeight) {
            if (imageWidth > imageHeight) {
                imageWidth = imageHeight;
            } else {
                imageHeight = imageWidth;
            }
        }
        if (isCenteredInXMaxSpace && width && (newXCoordinate + imageWidth < this.lastXCoordinate)) {
            imageXCoordinate = newXCoordinate + ((this.lastXCoordinate - newXCoordinate) / 2) - (imageWidth / 2);
        }
        if (isCenteredInYMaxSpace && height && (newYCoordinate + imageHeight < this.lastYCoordinate)) {
            imageYCoordinate = newYCoordinate + ((this.lastYCoordinate - newYCoordinate) / 2) - (imageHeight / 2);
        }
        this.doc.addImage(image, 'JPEG', imageXCoordinate, imageYCoordinate, imageWidth, imageHeight);
        if (!skipNewCurrentYCoordinate) {
            this.newCurrentYCoordinate(!isCenteredInYMaxSpace ? imageHeight : undefined, !isCenteredInYMaxSpace ? undefined : this.lastYCoordinate);
        }
        return (!isCenteredInXMaxSpace ? (newXCoordinate + imageWidth) : this.lastXCoordinate);
    }

    // addImageCenteredInMaxPossible = (image: string, height?: number, width?: number, squareImage?: boolean) => {
    //     let newHeight = height || (this.lastYCoordinate - this.currentYCoordinate);
    //     let newWidth = width;
    //     const xCoordinate = this.margin + (this.totalUsableWidth / 2) - ((newWidth || newHeight) / 2);
    //     if (!newWidth || (squareImage && newHeight !== newWidth)) {
    //         if (!newWidth || newWidth > newHeight) {
    //             newWidth = newHeight;
    //         } else {
    //             newHeight = newWidth;
    //         }
    //     }
    //     this.doc.addImage(image, 'JPEG', xCoordinate, this.currentYCoordinate, newWidth, newHeight);
    //     this.newCurrentYCoordinate(newHeight);
    // }

    obsArrayBuilder = (tareasIndexes: number[], etapaIndex: number): TareaObs[] => {
        const response: TareaObs[] = [];
        const etapa = this.etapas?.find((etapa, index) => index === etapaIndex);
        if (tareasIndexes.length > 0 && etapa) {
            tareasIndexes.forEach((tareaIndex) => {
                const tareaObs = etapa.tareas[tareaIndex].observations;
                if (tareaObs && tareaObs.value) {
                    response.push(tareaObs);
                }
            });
        }
        return response;
    }
}

export interface CoordinatesRange {
    start: number,
    end?: number
}

export interface ColumnWithValue {
    column: string,
    value: string
}

interface RGBColor {
    ch1: number,
    ch2: number,
    ch3: number
}

// Extraer símbolos para tablas de este listado para asegurar que sea el mismo
const getUnavailableSymbols = (): { symbol: string, replacement: string }[] => [
    { symbol: 'Ω', replacement: ' Ohm' }
];

export const getTableCellValueWithUnits = (columnsNameWithValue: ColumnWithValue[], targetColumnName: string, taskApply?: boolean, table?: EvaluacionOrReparacionComponenteDetallesTable, units?: string, withoutUnits?: boolean): string  => {
    let response = 'N/A';
    if (taskApply && table) {
        const getColumnIndexByName = (name: string): number => {
            let columnIndex = -1;
            for (let i = 0; i < table.columnsSettings.length; i++) {
                if (table.columnsSettings[i].columnTitle === name) {
                    columnIndex = i;
                    break;
                }
            }
            return columnIndex;
        }
        const columnsIndexWithValue = columnsNameWithValue.map((columnNameWithValue) => ({
            columnIndex: getColumnIndexByName(columnNameWithValue.column),
            value: columnNameWithValue.value
        }));
        if (columnsIndexWithValue.every((columnIndexWithValue) => columnIndexWithValue.columnIndex > -1)) {
            const targetColumnIndex = getColumnIndexByName(targetColumnName);
            if (targetColumnIndex > -1) {
                const rowTarget = table.rows.find((row) => columnsIndexWithValue.every((columnIndexWithValue) => row[columnIndexWithValue.columnIndex].content[0] === columnIndexWithValue.value));
                if (rowTarget && (!table.applySettings.applyOption || (rowTarget[0].apply && rowTarget[targetColumnIndex].apply))) {
                    let unitsText = '';
                    if (!withoutUnits) {
                        if (units) {
                            unitsText = ` ${units}`;
                        } else {
                            const cellUnits = ((): string => {
                                let unitsResponse = '';
                                let cellIndex = -1;
                                const nextCellIndex = targetColumnIndex + 1;
                                if (nextCellIndex < table.columnsSettings.length && table.columnsSettings[nextCellIndex].columnTitle === 'Unidad') {
                                    cellIndex = nextCellIndex;
                                }
                                if (cellIndex > -1 && rowTarget[cellIndex].apply) {
                                    unitsResponse = rowTarget[cellIndex].content[0];
                                }
                                return unitsResponse;
                            })();
                            if (cellUnits) {
                                unitsText = ` ${cellUnits}`;
                            }
                        }
                    }
                    rowTarget[targetColumnIndex].content.forEach((value, valueIndex) => {
                        let fixedValue = `${checkNumber(value).isNumber ? adjustDecimalTextLessThan1(adjustDecimalOrFractionTextGreaterThan1(value, true), true) : value}${unitsText}`;
                        response = valueIndex > 0 ? `${response}, ${fixedValue}` : fixedValue;
                    });
                }
            }
        }
    }
    return response;
}