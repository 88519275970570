import { ReactElement, useState } from "react";
import { Button, Card, Col, Modal, Row, Spinner, Table } from "react-bootstrap";
import { EvaluacionOrReparacionComponenteResumenEtapa } from "../../models/EvaluacionesReparaciones";
import { useHistory } from "react-router-dom";
import EditEvaluacionReparacionComponenteResumen from "./EditEvaluacionReparacionComponenteResumen";
import { AcceptedRolesElements, AcceptedUsersElements } from "../../models/users/Users";
import DetallesEvaluacionReparacionApi from "../../api/EvaluacionesReparacionesApi";
import EditAprobacionActividadesEvaluacionReparacionComponenteResumen from "./EditAprobacionActividadesEvaluacionReparacionComponenteResumen";

interface Props {
    evaulacionReparacionComponenteResumenEtapas: EvaluacionOrReparacionComponenteResumenEtapa[],
    codigoOP: string,
    changeEvaulacionReparacionComponenteResumenEtapa?: (evaulacionComponenteResumen: EvaluacionOrReparacionComponenteResumenEtapa) => void,
    isBusy: boolean,
    isAlreadyAprobada?: boolean,
    userPermissionFunction: (acceptedRolesElements?: AcceptedRolesElements, acceptedUsersElements?: AcceptedUsersElements) => boolean,
    userRoles: string[],
    evaluacionReparacionComponenteEtapaTareasApplyConst?: { nombreTarea: string, isConst: boolean }[],
    isTareaComplete?: (nombreTarea: string) => boolean,
    isEvaluacionReparacionNotComplete?: boolean,
    detallesApi: DetallesEvaluacionReparacionApi,
    userName?: string,
    isReparacion?: boolean
}

const EvaluacionReparacionComponenteResumen = ({
    codigoOP,
    evaulacionReparacionComponenteResumenEtapas,
    changeEvaulacionReparacionComponenteResumenEtapa,
    isBusy,
    userPermissionFunction,
    userRoles,
    evaluacionReparacionComponenteEtapaTareasApplyConst,
    isTareaComplete,
    isAlreadyAprobada,
    isEvaluacionReparacionNotComplete,
    detallesApi,
    userName,
    isReparacion
}: Props): ReactElement => {

    const [ showInicio, setShowInicio ] = useState<boolean>(false);
    const [ showAplica, setShowAplica ] = useState<boolean>(false);
    const [ showTermino, setShowTermino ] = useState<boolean>(false);
    const history = useHistory();

    const generalOnHide = () => {
        setShowInicio(false);
        setShowTermino(false);
        setShowAplica(false);
    }

    return (
        <>
            {evaulacionReparacionComponenteResumenEtapas.map((etapa) => {
                const isDisabled = isAlreadyAprobada || etapa.isNotAvailable || !userPermissionFunction(
                    { roles: ['ADMINISTRADOR', 'JEFETALLER', 'CALIDAD', 'SUPERVISOR'] },
                    etapa.participantes && { acceptedUsers: etapa.participantes.map((persona) => persona.email), acceptedRolesElements: { roles: ['OPERARIO'], isExcludingRoles: false, notExactRol: true } }
                ) || isBusy;
                const onClickDetails = () => history.push(`/ordenesProceso/${codigoOP}/${isReparacion ? 'reparacion' : 'evaluacion'}/${etapa.nombreEtapa}`);
                const columnas: string[] = [];
                etapa.tareas.forEach((tarea) => tarea.valores.forEach((valor) => {
                    if (!columnas.includes(valor.columna)) {
                        columnas.push(valor.columna);
                    }
                }));
                return (
                    <>
                        <Card className = "cards-container">
                            <Card.Header className = "cards-header">
                                {changeEvaulacionReparacionComponenteResumenEtapa ?
                                    <>
                                        <div className = "body-subtitle">
                                            Registro de actividades
                                        </div>
                                        <div className = "buttons-group">
                                            {!etapa.isEndDate && etapa.isTimeStamp ?
                                                <>
                                                    <Button variant="success" disabled = {isDisabled} onClick = {() => setShowInicio(true)}>
                                                        {isBusy ? <Spinner animation="border" size="sm" /> : 'Inicio'}
                                                    </Button>
                                                    <Button variant="danger" disabled = {isDisabled} onClick = {() => setShowTermino(true)}>
                                                        {isBusy ? <Spinner animation="border" size="sm" /> : 'Término'}
                                                    </Button>
                                                </>
                                            :
                                                ((): boolean => {
                                                    let response = false;
                                                    if (evaluacionReparacionComponenteEtapaTareasApplyConst) {
                                                        for (let i = 0; i < evaluacionReparacionComponenteEtapaTareasApplyConst.length; i++) {
                                                            if (!evaluacionReparacionComponenteEtapaTareasApplyConst[i].isConst) {
                                                                response = true;
                                                                break;
                                                            }
                                                        }
                                                    }
                                                    return response;
                                                })() &&
                                                <Button  disabled = {isDisabled} onClick = {() => setShowAplica(true)}>
                                                    {isBusy ? <Spinner animation="border" size="sm" /> : 'Aplica'}
                                                </Button>
                                            }
                                            {userName &&
                                                <EditAprobacionActividadesEvaluacionReparacionComponenteResumen
                                                    evaluacionReparacionComponenteResumenEtapa = {evaulacionReparacionComponenteResumenEtapas[0]}
                                                    detallesApi = {detallesApi}
                                                    userName = {userName}
                                                    isBusy = {isBusy}
                                                    isDisabled = {isAlreadyAprobada || !userPermissionFunction({ roles: ['ADMINISTRADOR', 'JEFETALLER', 'CALIDAD', 'SUPERVISOR'] }) || isBusy}
                                                />
                                            }
                                        </div>
                                    </>
                                :
                                    <>
                                        <div className = "body-subtitle">
                                            {etapa.nombreEtapa}
                                        </div>
                                        {(
                                            userRoles.includes('ADMINISTRADOR') ||
                                            userRoles.includes('JEFETALLER') ||
                                            userRoles.includes('CALIDAD') ||
                                            userRoles.some((rol) => rol.includes('OPERARIO'))
                                        ) &&
                                            <div className = "buttons-group">
                                                <Button onClick = {onClickDetails} disabled = {isDisabled}>
                                                    {isBusy ? <Spinner animation="border" size="sm" /> : 'Detalles'}
                                                </Button>
                                            </div>
                                        }
                                    </> 
                                }
                            </Card.Header>
                            <Card.Body className = "cards-body-groups">
                                <Table bordered size = 'sm'>
                                    <thead>
                                        <tr>
                                            <th>Actividad</th>
                                            {columnas.map((columna) => <th><div className = "centerTextCell">{columna}</div></th>)}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {etapa.tareas.map((tarea) => (
                                            <tr>
                                                <td>{tarea.nombreTarea}</td>
                                                {columnas.map((columna) => {
                                                    let valor: string | undefined;
                                                    let applyColumn = false;
                                                    for (let i = 0; i < tarea.valores.length; i++) {
                                                        if (tarea.valores[i].columna === columna) {
                                                            valor = tarea.valores[i].valor;
                                                            applyColumn = true;
                                                            break;
                                                        }
                                                    }
                                                    return (
                                                        <td><div className = "centerTextCell">{valor ? valor : (!applyColumn ? 'N/A' : '')}</div></td>
                                                    );
                                                })}
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                        {changeEvaulacionReparacionComponenteResumenEtapa && isTareaComplete && (showInicio || showTermino || showAplica) &&
                            <Modal show = {showInicio || showTermino || showAplica} onHide = {generalOnHide}>
                                <Modal.Header>
                                    <Col className = "text-center">
                                        <Modal.Title>{showAplica ? 'Realización' : showInicio ? 'Inicio' : showTermino && 'Término'} de actividades</Modal.Title>
                                    </Col>
                                </Modal.Header>
                                <Modal.Body>
                                    <EditEvaluacionReparacionComponenteResumen
                                        evaluacionReparacionComponenteResumenEtapa = {evaulacionReparacionComponenteResumenEtapas[0]}
                                        changeEvaulacionReparacionComponenteResumenEtapa = {changeEvaulacionReparacionComponenteResumenEtapa}
                                        showInicio = {showInicio}
                                        showAplica = {showAplica}
                                        showTermino = {showTermino}
                                        generalOnHide = {generalOnHide}
                                        evaluacionReparacionComponenteEtapaTareasApplyConst = {evaluacionReparacionComponenteEtapaTareasApplyConst}
                                        isTareaComplete = {isTareaComplete}
                                        isEvaluacionOrReparacionNotComplete = {isEvaluacionReparacionNotComplete}
                                        detallesApi = {detallesApi}
                                    />
                                </Modal.Body>
                            </Modal>
                        }
                    </>
                );
            })}
        </>
    );
}

export default EvaluacionReparacionComponenteResumen;