import { OrdenProceso } from "../../../models/estatusGeneral/OrdenProceso";
import portadaBlower from "../../../assets/images/blowers/portadaBlower.jpg";
import metrologiaVentiladores from "../../../assets/images/blowers/metrologiaVentiladores.jpg";
import metalizadoPistasU from "../../../assets/images/blowers/5GY19_U_L/metalizadoPistasU.jpg";
import metalizadoPistasL from "../../../assets/images/blowers/5GY19_U_L/metalizadoPistasL.jpg";
import { EvaluacionOrReparacionComponenteEtapa, EvaluacionOrReparacionComponenteEtapaInforme } from "../../../models/EvaluacionesReparaciones";
import { Archivo, ProcesoPhotoRequest } from "../../../models/Archivos";
import { getTableCellValueWithUnits, pdfElements } from "../../pdfElements";

const informeEvaluacion5GY19_U_LE = (ordenProceso: OrdenProceso, finalEtapa: EvaluacionOrReparacionComponenteEtapa, etapas: EvaluacionOrReparacionComponenteEtapaInforme[], fotos?: ProcesoPhotoRequest[], isPreview?: boolean, anexo?: Archivo) => {
    const informesElements = new pdfElements(ordenProceso, finalEtapa, etapas, fotos, anexo);
    const informeTitle = 'INFORME DE EVALUACIÓN\nMOTOR BLOWER';
    informesElements.addFrontPage(informeTitle, portadaBlower);
    informesElements.addPage();
    informesElements.identificacionCliente(
        [
            { name: 'ESTATOR S/N', value: ordenProceso.numeroSerieComponente.toUpperCase() || '' },
            { name: 'ARMADURA S/N', value: etapas.find((etapa) => etapa.nombre === 'Test eléctricos pre-lavado')?.tareas.find((tarea) => tarea.nombre === 'Número de serie piñón solar')?.inputs?.find((input) => input.name === 'Armadura S/N')?.value || '' }
        ],
        'G.E.'
    );
    informesElements.recepcionItem();
    informesElements.componentesDañadosAndFaltantesList();
    informesElements.addPage();
    informesElements.docSubtitleRectangle('TEST ELÉCTRICOS');
    const commonTestsElectricosTable = (etapa?: EvaluacionOrReparacionComponenteEtapaInforme) => {
        if (etapa?.nombre === 'Test eléctricos pre-lavado' || etapa?.nombre === 'Test eléctricos post-lavado') {
            const resistenciaElectricaTable = etapa.tareas[0].tables?.find((table, index) => index === 0);
            const aislacionTierraTable = etapa.tareas[1].tables?.find((table, index) => index === 0);
            const hipotTable = etapa.tareas[2].tables?.find((table, index) => index === 0);
            informesElements.addTable(
                [
                    {
                        rows: [[{ text: 'RESISTENCIA ELÉCTRICA', bold: true }]],
                        rowsColor: informesElements.rectanglesFillColor
                    },
                    {
                        rows: [
                            [
                                { text: 'PRUEBAS', bold: true },
                                { text: 'VALOR', bold: true },
                                { text: 'TEMPERATURA', bold: true },
                                { text: 'RESULTADO', bold: true }
                            ],
                            [
                                { text: 'ARMADURA', bold: true },
                                { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Armadura' }], 'Valor', etapa.tareas[0].applyElements.apply, resistenciaElectricaTable) },
                                { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Armadura' }], 'Temperatura (°C)', etapa.tareas[0].applyElements.apply, resistenciaElectricaTable, '°C') },
                                { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Armadura' }], 'Resultado', etapa.tareas[0].applyElements.apply, resistenciaElectricaTable) }
                            ],
                            [
                                { text: 'ESTATOR', bold: true },
                                { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Estator' }], 'Valor', etapa.tareas[0].applyElements.apply, resistenciaElectricaTable) },
                                { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Estator' }], 'Temperatura (°C)', etapa.tareas[0].applyElements.apply, resistenciaElectricaTable, '°C') },
                                { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Estator' }], 'Resultado', etapa.tareas[0].applyElements.apply, resistenciaElectricaTable) }
                            ],
                            [
                                { text: 'BOB.EXCITACIÓN', bold: true },
                                { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Bob. Excitación' }], 'Valor', etapa.tareas[0].applyElements.apply, resistenciaElectricaTable) },
                                { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Bob. Excitación' }], 'Temperatura (°C)', etapa.tareas[0].applyElements.apply, resistenciaElectricaTable, '°C') },
                                { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Bob. Excitación' }], 'Resultado', etapa.tareas[0].applyElements.apply, resistenciaElectricaTable) }
                            ],
                        ]
                    },
                ]
            );
            informesElements.addTable(
                [
                    {
                        rows: [[{ text: 'AISLACIÓN A TIERRA (MEGGER)', bold: true }]],
                        rowsColor: informesElements.rectanglesFillColor
                    },
                    {
                        rows: [
                            [
                                { text: 'PRUEBAS', bold: true },
                                { text: 'VALOR', bold: true },
                                { text: 'VOLTAJE APLICADO', bold: true },
                                { text: 'RESULTADO', bold: true }
                            ],
                            [
                                { text: 'ARMADURA', bold: true },
                                { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Armadura' }], 'Valor', etapa.tareas[1].applyElements.apply, aislacionTierraTable) },
                                { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Armadura' }], 'Voltaje aplicado (V)', etapa.tareas[1].applyElements.apply, aislacionTierraTable, 'V') },
                                { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Armadura' }], 'Resultado', etapa.tareas[1].applyElements.apply, aislacionTierraTable) }
                            ],
                            [
                                { text: 'ESTATOR', bold: true },
                                { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Estator' }], 'Valor', etapa.tareas[1].applyElements.apply, aislacionTierraTable) },
                                { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Estator' }], 'Voltaje aplicado (V)', etapa.tareas[1].applyElements.apply, aislacionTierraTable, 'V') },
                                { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Estator' }], 'Resultado', etapa.tareas[1].applyElements.apply, aislacionTierraTable) }
                            ],
                            [
                                { text: 'BOB.EXCITACIÓN', bold: true },
                                { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Bob. Excitación' }], 'Valor', etapa.tareas[1].applyElements.apply, aislacionTierraTable) },
                                { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Bob. Excitación' }], 'Voltaje aplicado (V)', etapa.tareas[1].applyElements.apply, aislacionTierraTable, 'V') },
                                { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Bob. Excitación' }], 'Resultado', etapa.tareas[1].applyElements.apply, aislacionTierraTable) }
                            ],
                        ]
                    },
                ]
            );
            informesElements.addTable(
                [
                    {
                        rows: [[{ text: 'HI-POT CORRIENTE DE FUGA', bold: true }]],
                        rowsColor: informesElements.rectanglesFillColor
                    },
                    {
                        rows: [
                            [
                                { text: 'PRUEBAS', bold: true },
                                { text: 'VALOR', bold: true },
                                { text: 'VOLTAJE APLICADO', bold: true }
                            ],
                            [
                                { text: 'ARMADURA', bold: true },
                                { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Armadura' }], 'Valor', etapa.tareas[2].applyElements.apply, hipotTable) },
                                { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Armadura' }], 'Voltaje aplicado (V)', etapa.tareas[2].applyElements.apply, hipotTable, 'V') }
                            ],
                            [
                                { text: 'ESTATOR', bold: true },
                                { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Estator' }], 'Valor', etapa.tareas[2].applyElements.apply, hipotTable) },
                                { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Estator' }], 'Voltaje aplicado (V)', etapa.tareas[2].applyElements.apply, hipotTable, 'V') }
                            ],
                            [
                                { text: 'BOB.EXCITACIÓN', bold: true },
                                { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Bob. Excitación' }], 'Valor', etapa.tareas[2].applyElements.apply, hipotTable) },
                                { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Bob. Excitación' }], 'Voltaje aplicado (V)', etapa.tareas[2].applyElements.apply, hipotTable, 'V') }
                            ],
                        ]
                    },
                ]
            );
        }
    }
    informesElements.docSubtitleRectangle('TEST ELÉCTRICOS PRE-LAVADO', undefined, true);
    commonTestsElectricosTable(etapas[4]);
    informesElements.rectangleObs('OBSERVACIONES DE LAS MEDICIONES ELÉCTRICAS PRE LAVADO', informesElements.obsArrayBuilder([0, 1, 2], 4));
    informesElements.addPage();
    informesElements.docSubtitleRectangle('TEST ELÉCTRICOS POST-LAVADO', undefined, true);
    const surgeArmaduraTable = etapas[8].tareas[3].tables?.find((table, index) => index === 0);
    const surgeEstatorTable = etapas[8].tareas[3].tables?.find((table, index) => index === 1);
    const pruebaBobinasTable = etapas[8].tareas[4].tables?.find((table, index) => index === 0);
    commonTestsElectricosTable(etapas[8]);
    informesElements.addTable(
        [
            {
                rows: [[{ text: 'SURGE TEST', bold: true }]],
                rowsColor: informesElements.rectanglesFillColor
            },
            {
                rows: [
                    [
                        { text: 'PRUEBAS', bold: true },
                        { text: 'MIN', bold: true, cellsWidthInUnits: 0.5 },
                        { text: 'MAX', bold: true, cellsWidthInUnits: 0.5 },
                        { text: 'MEDIA', bold: true, cellsWidthInUnits: 0.5 },
                        { text: 'ÁREA', bold: true, cellsWidthInUnits: 0.5 },
                        { text: 'CORR', bold: true, cellsWidthInUnits: 0.5 },
                        { text: 'VOLTAJE APLICADO', bold: true, cellsWidthInUnits: 2 },
                        { text: 'RESULTADO', bold: true, cellsWidthInUnits: 2 }
                    ],
                    [
                        { text: 'ARMADURA', bold: true },
                        { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Armadura' }], 'Mínimo (%)', etapas[8].tareas[3].applyElements.apply, surgeArmaduraTable, '%'), cellsWidthInUnits: 0.5 },
                        { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Armadura' }], 'Máximo (%)', etapas[8].tareas[3].applyElements.apply, surgeArmaduraTable, '%'), cellsWidthInUnits: 0.5 },
                        { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Armadura' }], 'Promedio (%)', etapas[8].tareas[3].applyElements.apply, surgeArmaduraTable, '%'), cellsWidthInUnits: 0.5 },
                        { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Armadura' }], 'Área (%)', etapas[8].tareas[3].applyElements.apply, surgeArmaduraTable, '%'), cellsWidthInUnits: 0.5 },
                        { text: 'N/A', cellsWidthInUnits: 0.5 },
                        { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Armadura' }], 'Voltaje aplicado (V)', etapas[8].tareas[3].applyElements.apply, surgeArmaduraTable, 'V'), cellsWidthInUnits: 2 },
                        { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Armadura' }], 'Resultado', etapas[8].tareas[3].applyElements.apply, surgeArmaduraTable), cellsWidthInUnits: 2 }
                    ],
                    [
                        { text: 'ESTATOR', bold: true },
                        { text: 'N/A', cellsWidthInUnits: 0.5 },
                        { text: 'N/A', cellsWidthInUnits: 0.5 },
                        { text: 'N/A', cellsWidthInUnits: 0.5 },
                        { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Estator' }], 'Área (%)', etapas[8].tareas[3].applyElements.apply, surgeEstatorTable, '%'), cellsWidthInUnits: 0.5 },
                        { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Estator' }], 'Correlación (%)', etapas[8].tareas[3].applyElements.apply, surgeEstatorTable, '%'), cellsWidthInUnits: 0.5 },
                        { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Estator' }], 'Voltaje aplicado (V)', etapas[8].tareas[3].applyElements.apply, surgeEstatorTable, 'V'), cellsWidthInUnits: 2 },
                        { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Estator' }], 'Resultado', etapas[8].tareas[3].applyElements.apply, surgeEstatorTable), cellsWidthInUnits: 2 }
                    ]
                ],
                totalCellsWidthInUnits: 7.5
            },
        ]
    );
    informesElements.addTable(
        [
            {
                rows: [[{ text: 'PRUEBA BOBINAS INDEPENDIENTES', bold: true }]],
                rowsColor: informesElements.rectanglesFillColor
            },
            {
                rows: [
                    [
                        { text: 'BOBINA', bold: true },
                        { text: 'RESISTENCIA', bold: true },
                        { text: 'INDUCTANCIA', bold: true },
                        { text: 'IMPEDANCIA', bold: true },
                        { text: 'AISLAMIENTO', bold: true }
                    ],
                    [
                        { text: 'EXCITACIÓN 2', bold: true },
                        { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Bobina de excitación 2' }], 'Resistencia', etapas[8].tareas[4].applyElements.apply, pruebaBobinasTable) },
                        { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Bobina de excitación 2' }], 'Inductancia', etapas[8].tareas[4].applyElements.apply, pruebaBobinasTable) },
                        { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Bobina de excitación 2' }], 'Impedancia', etapas[8].tareas[4].applyElements.apply, pruebaBobinasTable) },
                        { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Bobina de excitación 2' }], 'Aislamiento', etapas[8].tareas[4].applyElements.apply, pruebaBobinasTable) },
                    ],
                    [
                        { text: 'EXCITACIÓN 4', bold: true },
                        { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Bobina de excitación 4' }], 'Resistencia', etapas[8].tareas[4].applyElements.apply, pruebaBobinasTable) },
                        { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Bobina de excitación 4' }], 'Inductancia', etapas[8].tareas[4].applyElements.apply, pruebaBobinasTable) },
                        { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Bobina de excitación 4' }], 'Impedancia', etapas[8].tareas[4].applyElements.apply, pruebaBobinasTable) },
                        { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Bobina de excitación 4' }], 'Aislamiento', etapas[8].tareas[4].applyElements.apply, pruebaBobinasTable) },
                    ],
                    [
                        { text: 'EXCITACIÓN 8', bold: true },
                        { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Bobina de excitación 8' }], 'Resistencia', etapas[8].tareas[4].applyElements.apply, pruebaBobinasTable) },
                        { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Bobina de excitación 8' }], 'Inductancia', etapas[8].tareas[4].applyElements.apply, pruebaBobinasTable) },
                        { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Bobina de excitación 8' }], 'Impedancia', etapas[8].tareas[4].applyElements.apply, pruebaBobinasTable) },
                        { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Bobina de excitación 8' }], 'Aislamiento', etapas[8].tareas[4].applyElements.apply, pruebaBobinasTable) },
                    ],
                    [
                        { text: 'EXCITACIÓN 10', bold: true },
                        { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Bobina de excitación 10' }], 'Resistencia', etapas[8].tareas[4].applyElements.apply, pruebaBobinasTable) },
                        { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Bobina de excitación 10' }], 'Inductancia', etapas[8].tareas[4].applyElements.apply, pruebaBobinasTable) },
                        { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Bobina de excitación 10' }], 'Impedancia', etapas[8].tareas[4].applyElements.apply, pruebaBobinasTable) },
                        { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Bobina de excitación 10' }], 'Aislamiento', etapas[8].tareas[4].applyElements.apply, pruebaBobinasTable) },
                    ],
                    [
                        { text: 'CONMUTACIÓN 3', bold: true },
                        { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Bobina de conmutación 3' }], 'Resistencia', etapas[8].tareas[4].applyElements.apply, pruebaBobinasTable) },
                        { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Bobina de conmutación 3' }], 'Inductancia', etapas[8].tareas[4].applyElements.apply, pruebaBobinasTable) },
                        { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Bobina de conmutación 3' }], 'Impedancia', etapas[8].tareas[4].applyElements.apply, pruebaBobinasTable) },
                        { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Bobina de conmutación 3' }], 'Aislamiento', etapas[8].tareas[4].applyElements.apply, pruebaBobinasTable) },
                    ],
                    [
                        { text: 'CONMUTACIÓN 6', bold: true },
                        { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Bobina de conmutación 6' }], 'Resistencia', etapas[8].tareas[4].applyElements.apply, pruebaBobinasTable) },
                        { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Bobina de conmutación 6' }], 'Inductancia', etapas[8].tareas[4].applyElements.apply, pruebaBobinasTable) },
                        { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Bobina de conmutación 6' }], 'Impedancia', etapas[8].tareas[4].applyElements.apply, pruebaBobinasTable) },
                        { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Bobina de conmutación 6' }], 'Aislamiento', etapas[8].tareas[4].applyElements.apply, pruebaBobinasTable) },
                    ],
                    [
                        { text: 'CONMUTACIÓN 9', bold: true },
                        { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Bobina de conmutación 9' }], 'Resistencia', etapas[8].tareas[4].applyElements.apply, pruebaBobinasTable) },
                        { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Bobina de conmutación 9' }], 'Inductancia', etapas[8].tareas[4].applyElements.apply, pruebaBobinasTable) },
                        { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Bobina de conmutación 9' }], 'Impedancia', etapas[8].tareas[4].applyElements.apply, pruebaBobinasTable) },
                        { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Bobina de conmutación 9' }], 'Aislamiento', etapas[8].tareas[4].applyElements.apply, pruebaBobinasTable) },
                    ],
                    [
                        { text: 'CONMUTACIÓN 12', bold: true },
                        { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Bobina de conmutación 12' }], 'Resistencia', etapas[8].tareas[4].applyElements.apply, pruebaBobinasTable) },
                        { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Bobina de conmutación 12' }], 'Inductancia', etapas[8].tareas[4].applyElements.apply, pruebaBobinasTable) },
                        { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Bobina de conmutación 12' }], 'Impedancia', etapas[8].tareas[4].applyElements.apply, pruebaBobinasTable) },
                        { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Bobina de conmutación 12' }], 'Aislamiento', etapas[8].tareas[4].applyElements.apply, pruebaBobinasTable) },
                    ],
                ]
            },
        ]
    );
    informesElements.addPage();
    informesElements.rectangleObs('OBSERVACIONES DE LAS MEDICIONES ELÉCTRICAS POST LAVADO', informesElements.obsArrayBuilder([0, 1, 2, 3, 4], 8));
    informesElements.addPage();
    informesElements.docSubtitleRectangle('METROLOGÍA DE COMPONENTES');
    informesElements.docSubtitleRectangle('METROLOGÍA A COMPONENTES PRINCIPALES', undefined, true);
    const metrologiaCarcasaLadoConmutadorTable = etapas[6].tareas[1].tables?.find((table, index) => index === 0);
    const metrologiaCarcasaLadoBobinasTable = etapas[6].tareas[3].tables?.find((table, index) => index === 0);
    const metrologiaCajaPortaRodamientoTable = etapas[6].tareas[2].tables?.find((table, index) => index === 0);
    const getResultadoColumnValue = (rango: string): string => rango !== 'N/A' ? (rango === 'Dentro de rango' ? 'OK' : 'F.M.') : rango;
    const resultadoHolguraAspaCarcasaLadoConmutador = getTableCellValueWithUnits([{ column: 'Elemento', value: 'Holgura aspa carcasa lado conmutador' }], 'Resultado', etapas[6].tareas[1].applyElements.apply, metrologiaCarcasaLadoConmutadorTable, undefined, true);
    const resultadoHolguraAspaCarcasaLadoBobinas = getTableCellValueWithUnits([{ column: 'Elemento', value: 'Holgura aspa carcasa lado cola bobinas' }], 'Resultado', etapas[6].tareas[3].applyElements.apply, metrologiaCarcasaLadoBobinasTable, undefined, true);
    const resultadoEjeArmaduraLadoConmutador = getTableCellValueWithUnits([{ column: 'Elemento', value: 'Eje de armadura lado conmutador' }], 'Resultado', etapas[6].tareas[1].applyElements.apply, metrologiaCarcasaLadoConmutadorTable, undefined, true);
    const resultadoEjeArmaduraLadoBobinas = getTableCellValueWithUnits([{ column: 'Elemento', value: 'Eje armadura lado bobinas' }], 'Resultado', etapas[6].tareas[3].applyElements.apply, metrologiaCarcasaLadoBobinasTable, undefined, true);
    const resultadoCajaPortaRodamientoladoConmutadorExt = getTableCellValueWithUnits([{ column: 'Elemento', value: 'Caja porta rodamiento lado conmutador ext.' }], 'Resultado', etapas[6].tareas[2].applyElements.apply, metrologiaCajaPortaRodamientoTable, undefined, true);
    const resultadoCajaPortaRodamientoladoConmutadorInt = getTableCellValueWithUnits([{ column: 'Elemento', value: 'Caja porta rodamiento lado conmutador int.' }], 'Resultado', etapas[6].tareas[2].applyElements.apply, metrologiaCajaPortaRodamientoTable, undefined, true);
    const resultadoAlojamientoCarcasaLadoConmutador = getTableCellValueWithUnits([{ column: 'Elemento', value: 'Alojamiento carcasa lado conmutador' }], 'Resultado', etapas[6].tareas[1].applyElements.apply, metrologiaCarcasaLadoConmutadorTable, undefined, true);
    const resultadoAlojamientoCarcasaLadoBobinas = getTableCellValueWithUnits([{ column: 'Elemento', value: 'Alojamiento carcasa lado bobinas' }], 'Resultado', etapas[6].tareas[3].applyElements.apply, metrologiaCarcasaLadoBobinasTable, undefined, true);
    const resultadoConmutadorArmadura = getTableCellValueWithUnits([{ column: 'Elemento', value: 'Conmutador de armadura' }], 'Resultado', etapas[6].tareas[1].applyElements.apply, metrologiaCarcasaLadoConmutadorTable, undefined, true);
    const resultadoJuegoAxialEjeArmadura = getTableCellValueWithUnits([{ column: 'Elemento', value: 'Juego axial de eje de armadura' }], 'Resultado', etapas[6].tareas[1].applyElements.apply, metrologiaCarcasaLadoConmutadorTable, undefined, true);
    informesElements.addTable(
        [
            {
                totalCellsWidthInUnits: 6,
                rows: [[
                    { text: 'DIAMETROS', bold: true, cellHeightInUnits: 2, color: informesElements.rectanglesFillColor, cellsWidthInUnits: 2 },
                    { text: 'MEDICIÓN\n(PULGADAS)', bold: true, cellHeightInUnits: 2, color: informesElements.rectanglesFillColor },
                    { text: 'DENTRO O FUERA DE RANGOS', bold: true, cellHeightInUnits: 2, color: informesElements.rectanglesFillColor, cellsWidthInUnits: 2 },
                    { text: 'RESULTADOS', bold: true, cellHeightInUnits: 2, color: informesElements.rectanglesFillColor }
                ]]
            },
            {
                totalCellsWidthInUnits: 6,
                rows: [
                    [
                        { text: 'Holgura aspa carcasa lado conmutador', bold: true, cellsWidthInUnits: 2 },
                        { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Holgura aspa carcasa lado conmutador' }], 'Valor (pulgadas)', etapas[6].tareas[1].applyElements.apply, metrologiaCarcasaLadoConmutadorTable, '"') },
                        { text: resultadoHolguraAspaCarcasaLadoConmutador, cellsWidthInUnits: 2 },
                        { text: getResultadoColumnValue(resultadoHolguraAspaCarcasaLadoConmutador) },
                    ],
                    [
                        { text: 'Holgura aspa carcasa lado cola bobinas', bold: true, cellsWidthInUnits: 2 },
                        { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Holgura aspa carcasa lado cola bobinas' }], 'Valor (pulgadas)', etapas[6].tareas[3].applyElements.apply, metrologiaCarcasaLadoBobinasTable, '"') },
                        { text: resultadoHolguraAspaCarcasaLadoBobinas, cellsWidthInUnits: 2 },
                        { text: getResultadoColumnValue(resultadoHolguraAspaCarcasaLadoBobinas) },
                    ],
                    [
                        { text: 'Eje de armadura lado conmutador', bold: true, cellsWidthInUnits: 2 },
                        { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Eje de armadura lado conmutador' }], 'Valor (pulgadas)', etapas[6].tareas[1].applyElements.apply, metrologiaCarcasaLadoConmutadorTable, '"') },
                        { text: resultadoEjeArmaduraLadoConmutador, cellsWidthInUnits: 2 },
                        { text: getResultadoColumnValue(resultadoEjeArmaduraLadoConmutador) },
                    ],
                    [
                        { text: 'Eje armadura lado bobinas', bold: true, cellsWidthInUnits: 2 },
                        { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Eje armadura lado bobinas' }], 'Valor (pulgadas)', etapas[6].tareas[3].applyElements.apply, metrologiaCarcasaLadoBobinasTable, '"') },
                        { text: resultadoEjeArmaduraLadoBobinas, cellsWidthInUnits: 2 },
                        { text: getResultadoColumnValue(resultadoEjeArmaduraLadoBobinas) },
                    ],
                    [
                        { text: 'Alojamiento carcasa lado conmutador', bold: true, cellsWidthInUnits: 2 },
                        { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Alojamiento carcasa lado conmutador' }], 'Valor (pulgadas)', etapas[6].tareas[1].applyElements.apply, metrologiaCarcasaLadoConmutadorTable, '"') },
                        { text: resultadoAlojamientoCarcasaLadoConmutador, cellsWidthInUnits: 2 },
                        { text: getResultadoColumnValue(resultadoAlojamientoCarcasaLadoConmutador) },
                    ]
                ]
            },
            {
                totalCellsWidthInUnits: 6,
                rows: [[
                    { text: 'Caja porta rodamiento lado\nconmutador (exterior)', bold: true, cellsWidthInUnits: 2, cellHeightInUnits: 2 },
                    { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Caja porta rodamiento lado conmutador ext.' }], 'Valor (pulgadas)', etapas[6].tareas[2].applyElements.apply, metrologiaCajaPortaRodamientoTable, '"'), cellHeightInUnits: 2 },
                    { text: resultadoCajaPortaRodamientoladoConmutadorExt, cellsWidthInUnits: 2, cellHeightInUnits: 2 },
                    { text: getResultadoColumnValue(resultadoCajaPortaRodamientoladoConmutadorExt), cellHeightInUnits: 2 },
                ]]
            },
            {
                totalCellsWidthInUnits: 6,
                rows: [[
                    { text: 'Caja porta rodamiento lado\nconmutador (interior)', bold: true, cellsWidthInUnits: 2, cellHeightInUnits: 2 },
                    { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Caja porta rodamiento lado conmutador int.' }], 'Valor (pulgadas)', etapas[6].tareas[2].applyElements.apply, metrologiaCajaPortaRodamientoTable, '"'), cellHeightInUnits: 2 },
                    { text: resultadoCajaPortaRodamientoladoConmutadorInt, cellsWidthInUnits: 2, cellHeightInUnits: 2 },
                    { text: getResultadoColumnValue(resultadoCajaPortaRodamientoladoConmutadorInt), cellHeightInUnits: 2 },
                ]]
            },
            {
                totalCellsWidthInUnits: 6,
                rows: [[
                    { text: 'Alojamiento de rodamiento\nlado bobinas', bold: true, cellsWidthInUnits: 2, cellHeightInUnits: 2 },
                    { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Alojamiento carcasa lado bobinas' }], 'Valor (pulgadas)', etapas[6].tareas[3].applyElements.apply, metrologiaCarcasaLadoBobinasTable, '"'), cellHeightInUnits: 2 },
                    { text: resultadoAlojamientoCarcasaLadoBobinas, cellsWidthInUnits: 2, cellHeightInUnits: 2 },
                    { text: getResultadoColumnValue(resultadoAlojamientoCarcasaLadoBobinas), cellHeightInUnits: 2 },
                ]]
            },
            {
                totalCellsWidthInUnits: 6,
                rows: [
                    [
                        { text: 'Conmutador de armadura', bold: true, cellsWidthInUnits: 2 },
                        { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Conmutador de armadura' }], 'Valor (pulgadas)', etapas[6].tareas[1].applyElements.apply, metrologiaCarcasaLadoConmutadorTable, '"') },
                        { text: resultadoConmutadorArmadura, cellsWidthInUnits: 2 },
                        { text: getResultadoColumnValue(resultadoConmutadorArmadura) },
                    ],
                    [
                        { text: 'Juego axial de eje de armadura', bold: true, cellsWidthInUnits: 2 },
                        { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Juego axial de eje de armadura' }], 'Valor (pulgadas)', etapas[6].tareas[1].applyElements.apply, metrologiaCarcasaLadoConmutadorTable, '"') },
                        { text: resultadoJuegoAxialEjeArmadura, cellsWidthInUnits: 2 },
                        { text: getResultadoColumnValue(resultadoJuegoAxialEjeArmadura) },
                    ]
                ]
            }
        ]
    );
    informesElements.rectangleObs('OBSERVACIONES DE LAS METROLOGÍAS A COMPONENTES PRINCIPALES', informesElements.obsArrayBuilder([1, 2, 3], 6));
    informesElements.addPage();
    informesElements.docSubtitleRectangle('METROLOGÍA DE VENTILADORES (DESGASTE DE ALABES)', undefined, true);
    const commonAlabeTable = (isConmutador?: boolean): number => {
        const tareaIndex = isConmutador ? 1 : 0;
        const degree120Table = etapas[9].tareas[tareaIndex].tables?.find((table, index) => index === 0);
        const degree240Table = etapas[9].tareas[tareaIndex].tables?.find((table, index) => index === 1);
        const degree360Table = etapas[9].tareas[tareaIndex].tables?.find((table, index) => index === 2);
        return informesElements.addTable(
            [
                {
                    rows: [[{ text: `ALABE LADO ${isConmutador ? 'CONMUTADOR' : 'COLA DE BOBINAS'} (MÍNIMO 2,000")`, bold: true }]],
                    rowsColor: informesElements.rectanglesFillColor
                },
                {
                    rows: [
                        [
                            { text: 'POSICIÓN', bold: true },
                            { text: 'MEDIDA A', bold: true },
                            { text: 'MEDIDA B', bold: true },
                            { text: 'MEDIDA C', bold: true }
                        ],
                        [
                            { text: '120°', bold: true },
                            { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Medida A' }], 'Valor (pulgadas)', etapas[9].tareas[tareaIndex].applyElements.apply, degree120Table, '"') },
                            { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Medida B' }], 'Valor (pulgadas)', etapas[9].tareas[tareaIndex].applyElements.apply, degree120Table, '"') },
                            { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Medida C' }], 'Valor (pulgadas)', etapas[9].tareas[tareaIndex].applyElements.apply, degree120Table, '"') }
                        ],
                        [
                            { text: '240°', bold: true },
                            { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Medida A' }], 'Valor (pulgadas)', etapas[9].tareas[tareaIndex].applyElements.apply, degree240Table, '"') },
                            { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Medida B' }], 'Valor (pulgadas)', etapas[9].tareas[tareaIndex].applyElements.apply, degree240Table, '"') },
                            { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Medida C' }], 'Valor (pulgadas)', etapas[9].tareas[tareaIndex].applyElements.apply, degree240Table, '"') }
                        ],
                        [
                            { text: '360°', bold: true },
                            { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Medida A' }], 'Valor (pulgadas)', etapas[9].tareas[tareaIndex].applyElements.apply, degree360Table, '"') },
                            { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Medida B' }], 'Valor (pulgadas)', etapas[9].tareas[tareaIndex].applyElements.apply, degree360Table, '"') },
                            { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Medida C' }], 'Valor (pulgadas)', etapas[9].tareas[tareaIndex].applyElements.apply, degree360Table, '"') }
                        ],
                    ]
                },
            ],
            informesElements.totalUsableWidth - 85
        );
    };
    const currentYCoordinate = informesElements.currentYCoordinate;
    informesElements.addImage(
        metrologiaVentiladores,
        (informesElements.cellsHeight * 10) + informesElements.spaceBetweenElements,
        undefined,
        commonAlabeTable(),
        currentYCoordinate,
        false,
        false,
        false,
        true
    );
    commonAlabeTable(true);
    informesElements.rectangleObs('OBSERVACIONES DE LAS METROLOGÍAS DE VENTILADORES', informesElements.obsArrayBuilder([0, 1], 9));
    informesElements.addPage();
    informesElements.docSubtitleRectangle('TENSIÓN DE RESORTE CAJETÍN Y AISLACIÓN DE PORTA CARBONES', undefined, true);
    const aislacionPortaCarbonesTable = etapas[9].tareas[2].tables?.find((table, index) => index === 1);
    const tensionCajetinesTable = etapas[9].tareas[2].tables?.find((table, index) => index === 0);
    informesElements.addTable(
        [
            {
                rowCellsHeightInUnits: 2,
                rows: [
                    [
                        { text: 'AISLACIÓN PORTA\nCARBONES', bold: true },
                        { text: 'VALOR', bold: true }
                    ],
                    [
                        { text: 'Porta N°1', bold: true },
                        { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Porta N°1' }], 'Medición', etapas[9].tareas[2].applyElements.apply, aislacionPortaCarbonesTable) },
                    ],
                    [
                        { text: 'Porta N°2', bold: true },
                        { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Porta N°2' }], 'Medición', etapas[9].tareas[2].applyElements.apply, aislacionPortaCarbonesTable) },
                    ],
                    [
                        { text: 'Porta N°3', bold: true },
                        { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Porta N°3' }], 'Medición', etapas[9].tareas[2].applyElements.apply, aislacionPortaCarbonesTable) },
                    ],
                    [
                        { text: 'Porta N°4', bold: true },
                        { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Porta N°4' }], 'Medición', etapas[9].tareas[2].applyElements.apply, aislacionPortaCarbonesTable) },
                    ]
                ]
            }
        ],
        undefined,
        false,
        informesElements.addTable(
            [
                {
                    rowCellsHeightInUnits: 2,
                    totalCellsWidthInUnits: 4,
                    rows: [
                        [
                            { text: 'TENSIÓN RESORTE DE\nCAJETÍN', bold: true, cellsWidthInUnits: 2 },
                            { text: 'NÚMERO DE\nCAJETÍN', bold: true },
                            { text: 'VALOR', bold: true }
                        ]
                    ],
                },
                {
                    totalCellsWidthInUnits: 4,
                    rows: [
                        [
                            { text: 'Porta N°1', bold: true, cellsWidthInUnits: 2, cellHeightInUnits: 2 },
                            { text: 'Cajetín N°1', bold: true },
                            { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Porta N°1' }, { column: 'Número cajetín', value: 'Cajetín N°1' }], 'Medición (ozf)', etapas[9].tareas[2].applyElements.apply, tensionCajetinesTable, 'ozf') },
                        ]
                    ]
                },
                {
                    rows: [
                        [
                            { text: 'Cajetín N°2', bold: true },
                            { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Porta N°1' }, { column: 'Número cajetín', value: 'Cajetín N°2' }], 'Medición (ozf)', etapas[9].tareas[2].applyElements.apply, tensionCajetinesTable, 'ozf') },
                        ]
                    ]
                },
                {
                    totalCellsWidthInUnits: 4,
                    rows: [
                        [
                            { text: 'Porta N°2', bold: true, cellsWidthInUnits: 2, cellHeightInUnits: 2 },
                            { text: 'Cajetín N°1', bold: true },
                            { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Porta N°2' }, { column: 'Número cajetín', value: 'Cajetín N°1' }], 'Medición (ozf)', etapas[9].tareas[2].applyElements.apply, tensionCajetinesTable, 'ozf') },
                        ]
                    ]
                },
                {
                    rows: [
                        [
                            { text: 'Cajetín N°2', bold: true },
                            { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Porta N°2' }, { column: 'Número cajetín', value: 'Cajetín N°2' }], 'Medición (ozf)', etapas[9].tareas[2].applyElements.apply, tensionCajetinesTable, 'ozf') },
                        ]
                    ]
                },
                {
                    totalCellsWidthInUnits: 4,
                    rows: [
                        [
                            { text: 'Porta N°3', bold: true, cellsWidthInUnits: 2, cellHeightInUnits: 2 },
                            { text: 'Cajetín N°1', bold: true },
                            { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Porta N°3' }, { column: 'Número cajetín', value: 'Cajetín N°1' }], 'Medición (ozf)', etapas[9].tareas[2].applyElements.apply, tensionCajetinesTable, 'ozf') },
                        ]
                    ]
                },
                {
                    rows: [
                        [
                            { text: 'Cajetín N°2', bold: true },
                            { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Porta N°3' }, { column: 'Número cajetín', value: 'Cajetín N°2' }], 'Medición (ozf)', etapas[9].tareas[2].applyElements.apply, tensionCajetinesTable, 'ozf') },
                        ]
                    ]
                },
                {
                    totalCellsWidthInUnits: 4,
                    rows: [
                        [
                            { text: 'Porta N°4', bold: true, cellsWidthInUnits: 2, cellHeightInUnits: 2 },
                            { text: 'Cajetín N°1', bold: true },
                            { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Porta N°4' }, { column: 'Número cajetín', value: 'Cajetín N°1' }], 'Medición (ozf)', etapas[9].tareas[2].applyElements.apply, tensionCajetinesTable, 'ozf') },
                        ]
                    ]
                },
                {
                    rows: [
                        [
                            { text: 'Cajetín N°2', bold: true },
                            { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Porta N°4' }, { column: 'Número cajetín', value: 'Cajetín N°2' }], 'Medición (ozf)', etapas[9].tareas[2].applyElements.apply, tensionCajetinesTable, 'ozf') },
                        ]
                    ]
                }
            ],
            (informesElements.totalUsableWidth / 2) + (informesElements.totalUsableWidth / 9),
            true
        )
    );
    informesElements.rectangleObs(
        'OBSERVACIONES DE TENSIÓN RESORTE CAJETÍN Y AISLACIÓN PORTA CARBONES',
        informesElements.obsArrayBuilder([2], 9),
        false,
        (informesElements.lastYCoordinate - informesElements.firstYCoordinate - (informesElements.rectangleTitleHeight * 2) - (informesElements.cellsHeight * 15) - (informesElements.spaceBetweenElements * 5)) / 2
    );
    informesElements.docSubtitleRectangle('MEDICIÓN DE CARBONES (LARGO ESTÁNDAR 52,2 MM)', undefined, true);
    const medicionTable = etapas[9].tareas[3].tables?.find((table, index) => index === 0);
    informesElements.addTable(
        [
            {
                totalCellsWidthInUnits: 5,
                rows: [
                    [
                        { text: 'POSICIÓN', bold: true, cellsWidthInUnits: 0.5 },
                        { text: '1', bold: true, cellsWidthInUnits: 0.75 },
                        { text: '2', bold: true, cellsWidthInUnits: 0.75 },
                        { text: 'RESULTADO', bold: true },
                        { text: 'DAÑOS', bold: true, cellsWidthInUnits: 2 }
                    ],
                    [
                        { text: 'A', bold: true, cellsWidthInUnits: 0.5 },
                        { text: getTableCellValueWithUnits([{ column: 'Posición', value: 'A' }], '1', etapas[9].tareas[3].applyElements.apply, medicionTable), cellsWidthInUnits: 0.75 },
                        { text: getTableCellValueWithUnits([{ column: 'Posición', value: 'A' }], '2', etapas[9].tareas[3].applyElements.apply, medicionTable), cellsWidthInUnits: 0.75 },
                        { text: getTableCellValueWithUnits([{ column: 'Posición', value: 'A' }], 'Resultado', etapas[9].tareas[3].applyElements.apply, medicionTable) },
                        { text: getTableCellValueWithUnits([{ column: 'Posición', value: 'A' }], 'Daños', etapas[9].tareas[3].applyElements.apply, medicionTable), cellsWidthInUnits: 2 },
                    ],
                    [
                        { text: 'B', bold: true, cellsWidthInUnits: 0.5 },
                        { text: getTableCellValueWithUnits([{ column: 'Posición', value: 'B' }], '1', etapas[9].tareas[3].applyElements.apply, medicionTable), cellsWidthInUnits: 0.75 },
                        { text: getTableCellValueWithUnits([{ column: 'Posición', value: 'B' }], '2', etapas[9].tareas[3].applyElements.apply, medicionTable), cellsWidthInUnits: 0.75 },
                        { text: getTableCellValueWithUnits([{ column: 'Posición', value: 'B' }], 'Resultado', etapas[9].tareas[3].applyElements.apply, medicionTable) },
                        { text: getTableCellValueWithUnits([{ column: 'Posición', value: 'B' }], 'Daños', etapas[9].tareas[3].applyElements.apply, medicionTable), cellsWidthInUnits: 2 },
                    ],
                    [
                        { text: 'C', bold: true, cellsWidthInUnits: 0.5 },
                        { text: getTableCellValueWithUnits([{ column: 'Posición', value: 'C' }], '1', etapas[9].tareas[3].applyElements.apply, medicionTable), cellsWidthInUnits: 0.75 },
                        { text: getTableCellValueWithUnits([{ column: 'Posición', value: 'C' }], '2', etapas[9].tareas[3].applyElements.apply, medicionTable), cellsWidthInUnits: 0.75 },
                        { text: getTableCellValueWithUnits([{ column: 'Posición', value: 'C' }], 'Resultado', etapas[9].tareas[3].applyElements.apply, medicionTable) },
                        { text: getTableCellValueWithUnits([{ column: 'Posición', value: 'C' }], 'Daños', etapas[9].tareas[3].applyElements.apply, medicionTable), cellsWidthInUnits: 2 },
                    ],
                    [
                        { text: 'D', bold: true, cellsWidthInUnits: 0.5 },
                        { text: getTableCellValueWithUnits([{ column: 'Posición', value: 'D' }], '1', etapas[9].tareas[3].applyElements.apply, medicionTable), cellsWidthInUnits: 0.75 },
                        { text: getTableCellValueWithUnits([{ column: 'Posición', value: 'D' }], '2', etapas[9].tareas[3].applyElements.apply, medicionTable), cellsWidthInUnits: 0.75 },
                        { text: getTableCellValueWithUnits([{ column: 'Posición', value: 'D' }], 'Resultado', etapas[9].tareas[3].applyElements.apply, medicionTable) },
                        { text: getTableCellValueWithUnits([{ column: 'Posición', value: 'D' }], 'Daños', etapas[9].tareas[3].applyElements.apply, medicionTable), cellsWidthInUnits: 2 },
                    ],
                ]
            },
        ]
    );
    informesElements.rectangleObs('OBSERVACIONES DE MEDICIÓN DE CARBONES', informesElements.obsArrayBuilder([3], 9));

    informesElements.checkTablesPages({
        title: 'Esquema de pistas',
        image: ordenProceso.componente?.modelo.includes('L') ? metalizadoPistasL : metalizadoPistasU,
        width: (informesElements.totalUsableWidth / 2) + (informesElements.totalUsableWidth / 6)
    });
    informesElements.conclusionesGenerales();
    informesElements.addPhotosToDoc();
    informesElements.approvingTable(isPreview);

    informesElements.addHeaders(informeTitle, 'ELE-FR/SGC-026');
    informesElements.saveDocument();
}

export default informeEvaluacion5GY19_U_LE;