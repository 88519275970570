import cogoToast from "cogo-toast"
import { ReactElement, useState } from "react"
import { Button, Spinner, Table } from "react-bootstrap"
import { EvaluacionOrReparacionComponenteResumenEtapa } from "../../models/EvaluacionesReparaciones"
import DetallesEvaluacionReparacionApi from "../../api/EvaluacionesReparacionesApi"

interface Props {
    evaluacionReparacionComponenteResumenEtapa: EvaluacionOrReparacionComponenteResumenEtapa,
    changeEvaulacionReparacionComponenteResumenEtapa: (evaulacionComponenteResumen: EvaluacionOrReparacionComponenteResumenEtapa) => void,
    showInicio: boolean,
    showAplica: boolean,
    showTermino: boolean,
    generalOnHide: () => void,
    evaluacionReparacionComponenteEtapaTareasApplyConst?: { nombreTarea: string, isConst: boolean }[],
    isTareaComplete: (nombreTarea: string) => boolean,
    isEvaluacionOrReparacionNotComplete?: boolean,
    detallesApi: DetallesEvaluacionReparacionApi
}

const EditEvaluacionReparacionComponenteResumen = ({
    evaluacionReparacionComponenteResumenEtapa,
    changeEvaulacionReparacionComponenteResumenEtapa,
    showInicio,
    showAplica,
    showTermino,
    generalOnHide,
    evaluacionReparacionComponenteEtapaTareasApplyConst,
    isTareaComplete,
    isEvaluacionOrReparacionNotComplete,
    detallesApi
}: Props): ReactElement => {

    const isTermino = !showInicio && showTermino;
    const [ isSaving, setIsSaving ] = useState<boolean>(false);
    const getApplies = (): { check: boolean, disabled: boolean }[] | undefined => {
        if (showInicio || showAplica) {
            return evaluacionReparacionComponenteResumenEtapa.tareas.map((tarea) => {
                const response = { check: true, disabled: true };
                if (tarea.isNotAvailable || (['Trabajo por realizar', 'TOT por realizar', 'Listado de insumos'].includes(tarea.nombreTarea) && isEvaluacionOrReparacionNotComplete)) {
                    response.check = false;
                } else if (!evaluacionReparacionComponenteEtapaTareasApplyConst?.find((tarea2) => tarea2.nombreTarea === tarea.nombreTarea)?.isConst) {
                    for (let i = 0; i < tarea.valores.length; i++) {
                        if (tarea.valores[i].columna === 'SI/NO' && tarea.valores[i].valor === 'NO') {
                            response.check = false;
                        }
                        if (tarea.valores[i].columna === 'Fin' && !tarea.valores[i].valor) {
                            response.disabled = false;
                            break;
                        }
                    }
                }
                return response;
            });
        }
    }

    const [ tareasApply, setTareasApply ] = useState<{ check: boolean, disabled: boolean }[] | undefined>(getApplies());

    const getChecks = (): { check: boolean, disabled: boolean }[] => evaluacionReparacionComponenteResumenEtapa.tareas.map((tarea) => {
        const response = { check: true, disabled: false };
        if (tarea.isNotAvailable) {
            response.check = false;
            response.disabled = true;
        } else {
            if (showInicio && !showTermino) {
                for (let i = 0; i < tarea.valores.length; i++) {
                    if (tarea.valores[i].columna === 'SI/NO' && tarea.valores[i].valor && tarea.valores[i].valor === 'NO') {
                        response.check = false;
                        response.disabled = true;
                        break;
                    } else if ((tarea.valores[i].columna === 'Fin' || tarea.valores[i].columna === 'Fecha') && tarea.valores[i].valor) {
                        response.disabled = true;
                        break;
                    }
                }
            } else if (isTermino) {
                for (let i = 0; i < tarea.valores.length; i++) {
                    if (tarea.valores[i].columna === 'Inicio' && !tarea.valores[i].valor) {
                        response.check = false;
                        response.disabled = true;
                        break;
                    } else if (tarea.valores[i].columna === 'Fecha aprobación' && tarea.valores[i].valor) {
                        response.disabled = true;
                        break;
                    }
                }
                if (!response.disabled && !isTareaComplete(tarea.nombreTarea)) {
                    response.disabled = true;
                    response.check = false;
                }
            }
        }
        return response;
    });
    
    const [ evaluacionReparacionComponenteResumenEtapaForm, setEvaluacionReparacionComponenteResumenEtapaForm ] = useState<{ check: boolean, disabled: boolean }[]>(getChecks());

    const submitResumen = () => {
        setIsSaving(true);
        let newevaluacionReparacionComponenteResumenEtapa = evaluacionReparacionComponenteResumenEtapa;
        const nombreColumna = showInicio ? 'Inicio' : showTermino ? 'Fin' : showAplica ? 'Fecha' : '';
        evaluacionReparacionComponenteResumenEtapaForm.forEach((tarea, index) => {
            for (let e = 0; e < newevaluacionReparacionComponenteResumenEtapa.tareas[index].valores.length; e++) {
                if (tareasApply && newevaluacionReparacionComponenteResumenEtapa.tareas[index].valores[e].columna === 'SI/NO') {
                    newevaluacionReparacionComponenteResumenEtapa.tareas[index].valores[e].valor = tareasApply[index].check ? 'SI' : 'NO';
                } else if (newevaluacionReparacionComponenteResumenEtapa.tareas[index].valores[e].columna === nombreColumna) {
                    const check = showAplica && !showInicio && tareasApply ? tareasApply[index].check : tarea.check;
                    if (newevaluacionReparacionComponenteResumenEtapa.tareas[index].nombreTarea !== 'Aprobación jefe de taller' && check && !newevaluacionReparacionComponenteResumenEtapa.tareas[index].valores[e].valor) {
                        newevaluacionReparacionComponenteResumenEtapa.tareas[index].valores[e].valor = 'NUEVO';
                    } else if (!check && newevaluacionReparacionComponenteResumenEtapa.tareas[index].valores[e].valor) {
                        newevaluacionReparacionComponenteResumenEtapa.tareas[index].valores[e].valor = 'ELIMINAR';
                    }
                    break;
                }
            }
        });
        detallesApi.putEvaluacionOrReparacionComponenteResumen(newevaluacionReparacionComponenteResumenEtapa)
            .then((response) => {
                if (response.data) {
                    changeEvaulacionReparacionComponenteResumenEtapa(response.data);
                    cogoToast.success('Registro de actividades actualizado');
                    generalOnHide();
                    if (isTermino) {
                        window.location.reload();
                    }
                } else {
                    cogoToast.error('No fue posible actualizar el registro');
                }
                setIsSaving(false);                
            }).catch(() => {
                cogoToast.error('No fue posible actualizar el registro');
                setIsSaving(false);
            })
    }

    return (
        <>
            <Table bordered size = 'sm'>
                <thead>
                    <tr>
                        <th>Actividad</th>
                        {showInicio && <th><div className = "centerTextCell">¿Aplica?</div></th>}
                        <th>
                            <div className = "centerTextCell">
                                ¿{showAplica ? 'Aplica': showInicio && !showTermino ? 'Iniciada' : isTermino && 'Terminada'}?
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {evaluacionReparacionComponenteResumenEtapa.tareas.map((tarea, index) => (
                        <tr>
                            <td>{tarea.nombreTarea}</td>
                            {tareasApply &&
                                <td>
                                    <div className = "centerTextCell">
                                        <input
                                            className = "largeCheckBox"
                                            type = "checkbox"
                                            checked = {tareasApply[index].check}
                                            disabled = {tareasApply[index].disabled}
                                            onChange = {() => {
                                                let newFormApplies = tareasApply;
                                                let newFormChecks = evaluacionReparacionComponenteResumenEtapaForm;
                                                newFormApplies[index].check = !newFormApplies[index].check;
                                                if (newFormApplies[index].check) {
                                                    newFormChecks[index].disabled = false;
                                                } else {
                                                    newFormChecks[index].check = false;
                                                    newFormChecks[index].disabled = true;
                                                }
                                                setEvaluacionReparacionComponenteResumenEtapaForm([...newFormChecks])
                                                setTareasApply([...newFormApplies]);
                                            }}
                                        />
                                    </div>
                                </td>
                            }
                            {!showAplica &&
                                <td>
                                    <div className = "centerTextCell">
                                        <input
                                            className = "largeCheckBox"
                                            type = "checkbox"
                                            checked = {evaluacionReparacionComponenteResumenEtapaForm[index].check}
                                            disabled = {evaluacionReparacionComponenteResumenEtapaForm[index].disabled}
                                            onChange = {() => {
                                                let newForm = evaluacionReparacionComponenteResumenEtapaForm;
                                                newForm[index].check = !newForm[index].check;
                                                if (tareasApply) {
                                                    let applyForm = tareasApply;
                                                    if (newForm[index].check) {
                                                        applyForm[index].check = true;
                                                        applyForm[index].disabled = true;
                                                    } else {
                                                        applyForm[index].disabled = false;
                                                    }
                                                    setTareasApply([...applyForm]);
                                                }
                                                setEvaluacionReparacionComponenteResumenEtapaForm([...newForm]);
                                            }}
                                        />
                                    </div>
                                </td>
                            }
                        </tr>
                    ))}
                </tbody>
            </Table>
            {isEvaluacionOrReparacionNotComplete &&
                <div>
                    <small>
                        Para aplicar los listados "Trabajo por realizar", "TOT por realizar" e "Insumos" no debe haber ninguna tarea pendiente en las etapas anteriores
                    </small>
                </div>
            }
            <div className = "modal-buttons">
                <Button onClick = {submitResumen} variant = 'success' disabled = {isTermino ? (evaluacionReparacionComponenteResumenEtapaForm.every((value) => value.disabled) || isSaving) : isSaving}>
                    {isSaving ? <Spinner animation="border" size="sm" /> : 'Guardar'}
                </Button>
                <Button onClick = {() => {
                    setTareasApply(getApplies());
                    setEvaluacionReparacionComponenteResumenEtapaForm(getChecks());
                    generalOnHide();
                }} variant = 'secondary' disabled = {isSaving}>
                    {isSaving ? <Spinner animation="border" size="sm" /> : 'Cancelar'}
                </Button>
            </div>
        </>
    )
}

export default EditEvaluacionReparacionComponenteResumen;