import { ReactElement, useState } from "react";
import { EvaluacionOrReparacionComponenteResumenEtapa } from "../../models/EvaluacionesReparaciones";
import { Button, Col, Modal, Spinner, Table } from "react-bootstrap";
import DetallesEvaluacionReparacionApi from "../../api/EvaluacionesReparacionesApi";
import cogoToast from "cogo-toast";

interface Props {
    evaluacionReparacionComponenteResumenEtapa: EvaluacionOrReparacionComponenteResumenEtapa,
    detallesApi: DetallesEvaluacionReparacionApi,
    isDisabled: boolean,
    userName: string,
    isBusy: boolean
}

const EditAprobacionActividadesEvaluacionReparacionComponenteResumen = ({ detallesApi, evaluacionReparacionComponenteResumenEtapa, isDisabled, userName, isBusy }: Props): ReactElement => {
    
    const getTareasChecks = (): { nombre: string, check: boolean, disabled: boolean, tareaIndex: number, fechaAprobacionColumnIndex: number }[] => {
        const response: { nombre: string, check: boolean, disabled: boolean, tareaIndex: number, fechaAprobacionColumnIndex: number }[] = [];
        evaluacionReparacionComponenteResumenEtapa.tareas.forEach((tarea, tareaIndex) => {
            let fechaAprobacionColumnIndex = -1;
            const fechaAprobacionColumn = tarea.valores.find((valor, valorIndex) => {
                if (valor.columna === 'Fecha aprobación') {
                    fechaAprobacionColumnIndex = valorIndex;
                    return valor;
                }
            });
            if (fechaAprobacionColumn) {
                response.push({
                    nombre: tarea.nombreTarea,
                    check: !!fechaAprobacionColumn.valor,
                    disabled: !(tarea.valores.find((valor) => valor.columna === 'Fin') || tarea.valores.find((valor) => valor.columna === 'Fecha'))?.valor,
                    tareaIndex: tareaIndex,
                    fechaAprobacionColumnIndex: fechaAprobacionColumnIndex
                });
            }
        });
        return response;
    }
    const [ isSaving, setIsSaving ] = useState<boolean>(false);
    const [ show, setShow ] = useState<boolean>(false);
    const [ tareasChecks, setTareasChecks ] = useState<{ nombre: string, check: boolean, disabled: boolean, tareaIndex: number, fechaAprobacionColumnIndex: number }[]>(getTareasChecks());

    const hideModal = () => {
        setShow(false);
        setTareasChecks(getTareasChecks());
    }

    return (
        <>
            {tareasChecks.length > 0 &&
                <Button disabled = {isDisabled} onClick = {() => setShow(true)}>
                    {isBusy ? <Spinner animation="border" size="sm" /> : 'Aprobación'}
                </Button>
            }
            {show &&
                <Modal show = {show} onHide = {() => {
                    if (!isSaving) {
                        hideModal();
                    }
                }}>
                    <Modal.Header>
                        <Col className = "text-center">
                            <Modal.Title>Aprobación de actividades</Modal.Title>
                        </Col>
                    </Modal.Header>
                    <Modal.Body>
                        <Table bordered size = 'sm'>
                            <thead>
                                <tr>
                                    <th>Actividad</th>
                                    <th><div className = "centerTextCell">¿Aprobada?</div></th>
                                </tr>
                            </thead>
                            <tbody>
                                {tareasChecks.map((tareaCheck, tareaCheckIndex) => (
                                    <tr>
                                        <td>{tareaCheck.nombre}</td>
                                        <td>
                                            <div className = "centerTextCell">
                                                <input
                                                    className = "largeCheckBox"
                                                    type = "checkbox"
                                                    checked = {tareaCheck.check}
                                                    disabled = {tareaCheck.disabled}
                                                    onChange = {() => {
                                                        const newTareasChecks = tareasChecks;
                                                        newTareasChecks[tareaCheckIndex].check = !newTareasChecks[tareaCheckIndex].check;
                                                        setTareasChecks([...newTareasChecks]);
                                                    }}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <div className = "modal-buttons">
                            <Button onClick = {() => {
                                setIsSaving(true);
                                const newEvaluacionReparacionComponenteResumenEtapa = evaluacionReparacionComponenteResumenEtapa;
                                tareasChecks.forEach((tareaCheck) => {
                                    const usersListString = newEvaluacionReparacionComponenteResumenEtapa.tareas[tareaCheck.tareaIndex].valores[tareaCheck.fechaAprobacionColumnIndex - 1].valor;
                                    const usersList = usersListString ? usersListString.split(' / ') : [];
                                    if ((usersList.length > 0) || tareaCheck.check) {
                                        if (!usersList.includes(userName)) {
                                            usersList.push(userName);
                                        }
                                        newEvaluacionReparacionComponenteResumenEtapa.tareas[tareaCheck.tareaIndex].valores[tareaCheck.fechaAprobacionColumnIndex - 1].valor = usersList.toString().replaceAll(',', ' / ');
                                        if (newEvaluacionReparacionComponenteResumenEtapa.tareas[tareaCheck.tareaIndex].valores[tareaCheck.fechaAprobacionColumnIndex].valor) {
                                            if (!tareaCheck.check) {
                                                newEvaluacionReparacionComponenteResumenEtapa.tareas[tareaCheck.tareaIndex].valores[tareaCheck.fechaAprobacionColumnIndex].valor = 'ELIMINAR';
                                            }
                                        } else {
                                            if (tareaCheck.check) {
                                                newEvaluacionReparacionComponenteResumenEtapa.tareas[tareaCheck.tareaIndex].valores[tareaCheck.fechaAprobacionColumnIndex].valor = 'NUEVO';
                                            }
                                        }
                                    }
                                });
                                detallesApi.putEvaluacionOrReparacionComponenteResumen(newEvaluacionReparacionComponenteResumenEtapa)
                                    .then(() => {
                                        setIsSaving(false);
                                        hideModal();
                                        window.location.reload();
                                    }).catch(() => {
                                        cogoToast.error('No fue posible actualizar el control de calidad');
                                        setIsSaving(false);
                                    });
                            }} variant = 'success' disabled = {tareasChecks.every((tareaCheck) => tareaCheck.disabled) || isSaving}>
                                {isSaving ? <Spinner animation="border" size="sm" /> : 'Guardar'}
                            </Button>
                            <Button onClick = {hideModal} variant = 'secondary' disabled = {isSaving}>
                                {isSaving ? <Spinner animation="border" size="sm" /> : 'Cancelar'}
                            </Button>
                        </div>
                    </Modal.Body>
                </Modal>
            }
        </>
    );
}

export default EditAprobacionActividadesEvaluacionReparacionComponenteResumen;