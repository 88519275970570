import cogoToast from "cogo-toast";
import { ReactElement, useState } from "react";
import { Button, Card, Col, Modal, Row, Spinner } from "react-bootstrap";
import useFormState from "../../hooks/useFormState";
import useTOTOptions from "../../hooks/useTOTOptions";
import { OrdenProceso } from "../../models/estatusGeneral/OrdenProceso";
import { Evaluacion, EvaluacionForm } from "../../models/Evaluaciones";
import { formatEvaluacionForm, formatEvaluacionRequestBody, generarInformeEvaluacionReparacionComponente, generarListadoInsumos } from "../../utils/evaluaciones/evaluaciones";
import EvaluacionFormComponent from "./EvaluacionFormComponent";
import EvaluacionReparacionComponenteResumen from "../evaluacionesReparaciones/EvaluacionReparacionComponenteResumen";
import { AcceptedRolesElements, AcceptedUsersElements } from "../../models/users/Users";
import { ProcesoPhotoRequest } from "../../models/Archivos";
import { getDocumentoEvaluacionResumen, getImagenProceso } from "../../api/DocumentosApi";
import CorreoEntregaEvaluacionReparacion from "../evaluacionesReparaciones/CorreoEntregaEvaluacionReparacion";
import { EvaluacionOrReparacionComponenteResumenEtapa } from "../../models/EvaluacionesReparaciones";
import { putEvaluacionCodigoOP, putEvaluacionRealizadoPor } from "../../api/EvaluacionesApi";
import DetallesEvaluacionReparacionApi from "../../api/EvaluacionesReparacionesApi";
import DocumentosEvaluacion from "../files/DocumentosEvaluacion";

interface Props {
    evaluacion: Evaluacion,
    changeEvaluacion: (evaluacion: Evaluacion) => void,
    ordenProceso: OrdenProceso,
    evaulacionReparacionComponenteResumenEtapas?: EvaluacionOrReparacionComponenteResumenEtapa[],
    userPermissionFunction: (acceptedRolesElements?: AcceptedRolesElements, acceptedUsersElements?: AcceptedUsersElements) => boolean,
    userData: { userName: string, userEmail: string, userRoles: string[] },
    detallesApi: DetallesEvaluacionReparacionApi
}

const EvaluacionDetails = ({ evaluacion, changeEvaluacion, userPermissionFunction, ordenProceso, evaulacionReparacionComponenteResumenEtapas, userData, detallesApi }: Props): ReactElement => {

    const [ isBusy, setIsBusy ] = useState<boolean>(false);
    const [ showEdit, setShowEdit ] = useState<boolean>(false);
    const [ isDownloading, setIsDownloading ] = useState<boolean>(false);
    const editPermission = userPermissionFunction({ roles: ['ADMINISTRADOR', 'JEFETALLER', 'CALIDAD', 'SUPERVISOR'] }, evaluacion.participantesEmails ? { acceptedUsers: evaluacion.participantesEmails } : undefined);
    const totOPtions = useTOTOptions();
    const formStateEdit = useFormState(formatEvaluacionForm(evaluacion));
    const isNotEvaluacionAprobada = !!evaulacionReparacionComponenteResumenEtapas &&
        !evaulacionReparacionComponenteResumenEtapas.find((resumenEtapa) => resumenEtapa.nombreEtapa === 'Informe de evaluación')
            ?.tareas?.find((resumenEtapaTarea) => resumenEtapaTarea.nombreTarea === 'Aprobación jefe de taller')
                ?.valores.find((resumenEtapaTareaValor) => resumenEtapaTareaValor.columna === 'Fecha')?.valor

    const handleShowEdit = () => setShowEdit(true);
    const handleCloseEdit = () => {
        formStateEdit.reset();
        setShowEdit(false);
    };

    const changeStates = (state: boolean) => {
        setIsDownloading(state);
        setIsBusy(state);
        // if (!addAttachments) {
        //     setIsDownloadingImages(state);
        //     setIsBusy(state);
        // }
    };

    const submitForm = (evaluacionForm: EvaluacionForm) => {
        putEvaluacionCodigoOP(formatEvaluacionRequestBody(evaluacionForm), ordenProceso.codigoOP)
            .then((response) => {
                if (response.data.fechaInicial) {
                    formStateEdit.setSubmitting(false);
                    formStateEdit.setForm(evaluacionForm);
                    setShowEdit(false);
                    changeEvaluacion(response.data);
                    cogoToast.success('Evaluacion Editada');
                } else {
                    cogoToast.success('No fue posible editar la evaluación');
                }
            })
            .catch(() => {
                cogoToast.error('No fue posible editar la evaluación');
                formStateEdit.setSubmitting(false);
            })
    }

    const generateFile = async (nombreProceso: string) => {
        changeStates(true);
        await detallesApi.getEvaluacionOrReparacionComponenteEtapa('Informe de evaluación')
            .then((etapaResponse) => {
                if (etapaResponse.data) {
                    detallesApi.getEvaluacionOrReparacionComponenteEtapas()
                        .then(async (etapasResponse) => {
                            if (etapasResponse.data && etapasResponse.data.length > 0) {
                                const photos: ProcesoPhotoRequest[] = [];
                                let documentoEvaluacionResumen: { nombre: string, base64: string } | undefined = undefined;
                                etapasResponse.data.forEach((etapa) => etapa.tareas.forEach((tarea) => tarea.photos?.forEach((photo) => photos.push({
                                    nombreVariable: photo.title,
                                    codigoOP: ordenProceso.codigoOP,
                                    nombreProceso: nombreProceso === 'evaluación' ? 'evaluacion' : nombreProceso,
                                    nombreEtapa: etapa.nombre,
                                    nombreTarea: tarea.nombre
                                }))));
                                for (let i = 0; i < photos.length; i++) {
                                    await getImagenProceso(photos[i])
                                        .then((photoResponse) => {
                                            if (photoResponse.data) {
                                                photos[i].imagen = photoResponse.data;
                                            }
                                        })
                                        .catch(() => {
                                            cogoToast.error(`No fue posible obtener la imagen ${photos[i].nombreVariable}`);
                                        })
                                }
                                await getDocumentoEvaluacionResumen(ordenProceso.codigoOP)
                                    .then((response) => {
                                        if (response.data?.base64) {
                                            documentoEvaluacionResumen = { nombre: response.data.nombre, base64: response.data.base64 };
                                        }
                                    }).catch(() => cogoToast.error('No fue posible obtener el documento'));
                                generarInformeEvaluacionReparacionComponente(ordenProceso, etapaResponse.data, etapasResponse.data, photos, false, documentoEvaluacionResumen);
                                cogoToast.success('Informe generado');
                                // const informe = generarInformeEvaluacionComponente(ordenProceso, etapaResponse.data, etapasResponse.data, photos, undefined, !!addAttachments);
                                // if (addAttachments && informe) {
                                //     addAttachments([informe]);
                                // } else {
                                //     cogoToast.success('Informe generado');
                                // }
                            } else {
                                cogoToast.error('No fue posible generar el informe');
                            }
                            changeStates(false);
                        })
                        .catch((error) => {
                            changeStates(false);
                            console.log(error)
                            cogoToast.error('No fue posible generar el informe');
                        })
                } else {
                    cogoToast.error('No fue posible obtener el detalle de la evaluación');
                    changeStates(false);
                }
            })
            .catch(() => {
                cogoToast.error('No fue posible obtener el detalle de la evaluación');
                changeStates(false);
            })
    }

    return (
        <>
            <Card className = "cards-container">
                <Card.Header className = "cards-header">
                    <div className = "body-subtitle">
                        Evaluación de componente
                    </div>
                        {isDownloading ?
                            <div>Generando informe ... <Spinner animation="border" size="sm" /></div>
                        :
                            <div className = "buttons-group">
                                {editPermission &&
                                    <Button onClick = {handleShowEdit} disabled = {isBusy}>{isBusy ? <Spinner animation="border" size="sm" /> : 'Editar'}</Button>
                                }
                                {editPermission &&
                                    <CorreoEntregaEvaluacionReparacion
                                        userData = {userData}
                                        ordenProceso = {ordenProceso}
                                        isBusy = {isBusy}
                                        setIsBusy = {(state: boolean) => setIsBusy(state)}
                                        // generateFile = {generateFile}
                                        fechaFinal = {evaluacion.fechaFinal}
                                        nombreEtapaProceso = "evaluación"
                                        // getFiles = {async (handleDocumentosResponse: (responseData?: Archivo[]) => void) => await getDocumentosEvaluacionTecnica(ordenProceso.codigoOP)
                                        //     .then((response) => handleDocumentosResponse(response.data))
                                        //     .catch(() => cogoToast.error('No fue posible obtener algunos documentos de evaluación'))
                                        // }
                                        userPermission = {userPermissionFunction({ roles: ['ADMINISTRADOR', 'JEFETALLER', 'CALIDAD', 'SUPERVISOR'] })}
                                        evaluacionUpdate = {async (realizadoPor?: string, grado?: string, notas?: string): Promise<void> => {
                                            await putEvaluacionRealizadoPor(ordenProceso.codigoOP, realizadoPor, grado, notas)
                                                .then().catch(() => cogoToast.error('No fue posible actualizar la evaluación'))
                                        }}
                                    />
                                }
                                {ordenProceso.componente?.tipoComponente && evaulacionReparacionComponenteResumenEtapas &&
                                    <>
                                        <Button
                                            disabled = {isNotEvaluacionAprobada || isBusy}
                                            onClick = {() => generateFile('evaluacion')}
                                            variant = "success"
                                        >
                                            {isBusy ? <Spinner animation="border" size="sm" /> : 'Generar informe'}
                                        </Button>
                                        <Button
                                            disabled = {isNotEvaluacionAprobada || isBusy}
                                            variant = "success"
                                            onClick = {async () => {
                                                changeStates(true);
                                                await detallesApi.getEvaluacionOrReparacionComponenteEtapa('Informe de evaluación')
                                                    .then((response) => {
                                                        if (response.data) {
                                                            generarListadoInsumos(ordenProceso, response.data);
                                                        } else {
                                                            cogoToast.error('No fue posible generar el listado de insumos');    
                                                        }
                                                        changeStates(false);
                                                    }).catch((error) => {
                                                        console.log(error)
                                                        cogoToast.error('No fue posible generar el listado de insumos');
                                                        changeStates(false);
                                                    })
                                            }}
                                        >
                                            {isBusy ? <Spinner animation="border" size="sm" /> : 'Generar listado insumos'}
                                        </Button>
                                    </>
                                }
                            </div>
                        }
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col>
                            Fecha inicial
                            <h3>{evaluacion.fechaInicial}</h3>
                        </Col>
                        <Col>
                            Fecha final
                            <h3>{evaluacion.fechaFinal}</h3>
                        </Col>
                        <Col>
                            T.O.T.
                            <h3>{evaluacion.tot}</h3>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <DocumentosEvaluacion
                codigoOP = {ordenProceso.codigoOP}
                isBusy = {isBusy}
                changeBusyState = {(state: boolean) => setIsBusy(state)}
                userPermission = {editPermission}
                addMessage = {ordenProceso.desarmeEvaluacionComponente}
                documentosListado = {evaluacion.documentosListado}
            />
            {evaulacionReparacionComponenteResumenEtapas && 
                <EvaluacionReparacionComponenteResumen
                    codigoOP = {ordenProceso.codigoOP}
                    evaulacionReparacionComponenteResumenEtapas = {evaulacionReparacionComponenteResumenEtapas}
                    userPermissionFunction = {userPermissionFunction}
                    userRoles = {userData.userRoles}
                    isBusy = {isBusy}
                    detallesApi = {detallesApi}
                    userName = {userData.userName}
                />
            }
            {showEdit && ordenProceso.recepcion?.fechaRecepcion && (
                <Modal show = {showEdit} onHide = {handleCloseEdit}>
                    <Modal.Header>
                        <Col className = "text-center">
                            <Modal.Title>Editar Evaluación</Modal.Title>
                        </Col>
                    </Modal.Header>
                    <Modal.Body>
                        <EvaluacionFormComponent
                            formState = {formStateEdit}
                            submitForm = {submitForm}
                            onCancel = {handleCloseEdit}
                            totOptions = {totOPtions}
                            minDateEvaluacion = {ordenProceso.recepcion?.fechaRecepcion}
                            withDesarmeEvaluacion = {ordenProceso.desarmeEvaluacionComponente}
                        />
                    </Modal.Body>
                </Modal>
            )}
        </>
    );
};

export default EvaluacionDetails;